var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticClass: "grades", attrs: { color: "#ffffff" } },
    [
      (_vm.$vuetify.breakpoint.smAndDown || _vm.localIsMenuOverlapping) &&
      _vm.$currentUser.isTeacher &&
      _vm.showSlideGroup
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _c(
                "v-slide-group",
                { staticClass: "py-2", attrs: { "center-active": "" } },
                [
                  _c(
                    "v-slide-item",
                    { key: 0 },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            transition: "scroll-y-transition",
                            "offset-y": "",
                            "max-height": "500"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "primary",
                                              outlined: "",
                                              rounded: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedClass
                                                ? _vm.selectedClass.text
                                                : ""
                                            )
                                          )
                                        ]),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { small: "" }
                                          },
                                          [_vm._v("fal fa-chevron-down")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            615799806
                          )
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.classList, function(classItem) {
                              return _c(
                                "v-list-item",
                                {
                                  key: classItem.cId,
                                  class: {
                                    "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                      _vm.selectedClass.value == classItem.value
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.selectedClass = classItem
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(classItem.text) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-slide-item",
                    { key: 1 },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            transition: "scroll-y-transition",
                            "offset-y": "",
                            "max-height": "500"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "primary",
                                              outlined: "",
                                              rounded: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.selectedPeriod.name)
                                          )
                                        ]),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { small: "" }
                                          },
                                          [_vm._v("fal fa-chevron-down")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2264950669
                          )
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.periodList, function(periodItem) {
                              return _c(
                                "v-list-item",
                                {
                                  key: periodItem.cId,
                                  class: {
                                    "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                      _vm.selectedPeriod.gradePeriodId ==
                                      periodItem.gradePeriodId
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.selectedPeriod = periodItem
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(periodItem.name) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : (_vm.$vuetify.breakpoint.smAndDown || _vm.localIsMenuOverlapping) &&
          _vm.$currentUser.isAdmin
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _vm.$currentUser.isDistrictAdmin || _vm.haveMultipleSchools
                ? _c(
                    "v-slide-group",
                    {
                      staticClass: "py-2",
                      attrs: { "center-active": "" },
                      model: {
                        value: _vm.localSlideGroupModel,
                        callback: function($$v) {
                          _vm.localSlideGroupModel = $$v
                        },
                        expression: "localSlideGroupModel"
                      }
                    },
                    [
                      _c(
                        "v-slide-item",
                        { key: 0 },
                        [
                          _c("v-autocomplete", {
                            staticClass: "ml-2 pill-mobile-filters",
                            staticStyle: {
                              "max-width": "200px",
                              "font-size": "12px"
                            },
                            attrs: {
                              rounded: "",
                              dense: "",
                              flat: "",
                              solo: "",
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.schoolItems
                            },
                            model: {
                              value: _vm.currentSchoolId,
                              callback: function($$v) {
                                _vm.currentSchoolId = $$v
                              },
                              expression: "currentSchoolId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.$currentUser.isTeacher
                ? _c(
                    "v-card",
                    { staticClass: "rounded-0", attrs: { flat: "" } },
                    [
                      _c("grades-table-teacher", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.classList.length > 0,
                            expression: "classList.length > 0"
                          }
                        ],
                        ref: "gradesTable",
                        attrs: {
                          selectedClass: _vm.selectedClass,
                          selectedPeriod: _vm.selectedPeriod.gradePeriodId
                        },
                        on: {
                          showSlideGroup: _vm.updateSlideGroup,
                          showPeriod: _vm.openGradePeriod
                        }
                      })
                    ],
                    1
                  )
                : _vm.$currentUser.isAdmin
                ? _c("grades-admin-view", { ref: "adminGrades" })
                : _vm.$currentUser.isStudent
                ? _c(
                    "v-card",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown
                        ? "mx-0"
                        : "mt-5 d-flex justify-center",
                      attrs: { flat: "" }
                    },
                    [
                      _c("grades-table", {
                        ref: "studentGradesTable",
                        class: _vm.$vuetify.breakpoint.smAndDown
                          ? "mx-0"
                          : "mx-8",
                        attrs: { studentId: _vm.getSettings.userId }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("score-weight-dialog", {
        attrs: { value: _vm.dialog.scoreWeight },
        on: {
          close: function($event) {
            _vm.dialog.scoreWeight = false
          },
          saved: function($event) {
            _vm.dialog.scoreWeight = false
            _vm.$refs.gradesTable.refreshTable()
          }
        }
      }),
      _c("performance-report", {
        attrs: {
          value: _vm.dialog.performanceReport,
          selectedPeriod: _vm.selectedPeriod
        },
        on: {
          close: function($event) {
            _vm.dialog.performanceReport = false
          }
        }
      }),
      _c("print-grades", {
        ref: "printGrades",
        attrs: {
          value: _vm.dialog.print,
          classList: _vm.classList,
          selectedClass: _vm.selectedClass,
          selectedPeriod: _vm.selectedPeriod
        },
        on: {
          "update:value": function($event) {
            return _vm.$set(_vm.dialog, "print", $event)
          }
        }
      }),
      _c(
        "pb-base-modal",
        {
          attrs: {
            maxWidth: 700,
            expandable: false,
            withActions: false,
            withApply: false
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_vm._v(_vm._s(_vm.$t("gradingPeriodLabel")))]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.dialog.gradingPeriod,
            callback: function($$v) {
              _vm.$set(_vm.dialog, "gradingPeriod", $$v)
            },
            expression: "dialog.gradingPeriod"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-7 pl-6 pr-6" },
            [
              _c(
                "v-form",
                { ref: "gradePeriodForm", attrs: { "lazy-validation": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("titleLabel"),
                              "hide-details": "auto",
                              rules:
                                _vm.activePeriod.name === ""
                                  ? [_vm.$t("gradingPeriodErrorMsg5")]
                                  : [],
                              outlined: "",
                              readonly: ""
                            },
                            model: {
                              value: _vm.activePeriod.name,
                              callback: function($$v) {
                                _vm.$set(_vm.activePeriod, "name", $$v)
                              },
                              expression: "activePeriod.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("pb-date-time-range-field", {
                            attrs: {
                              hideDetails: "auto",
                              label: _vm.$t("dateLabel"),
                              start: _vm.activePeriod.startDate,
                              end: _vm.activePeriod.endDate,
                              readonly: true
                            },
                            on: {
                              "update:start": function($event) {
                                return _vm.$set(
                                  _vm.activePeriod,
                                  "startDate",
                                  $event
                                )
                              },
                              "update:end": function($event) {
                                return _vm.$set(
                                  _vm.activePeriod,
                                  "endDate",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }