import { render, staticRenderFns } from "./GradesAdminView.vue?vue&type=template&id=1567ad56&scoped=true"
import script from "./GradesAdminView.vue?vue&type=script&lang=ts"
export * from "./GradesAdminView.vue?vue&type=script&lang=ts"
import style0 from "./GradesAdminView.vue?vue&type=style&index=0&id=1567ad56&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1567ad56",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCard,VCardTitle,VCol,VIcon,VImg,VList,VListItem,VRow,VSheet,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1567ad56')) {
      api.createRecord('1567ad56', component.options)
    } else {
      api.reload('1567ad56', component.options)
    }
    module.hot.accept("./GradesAdminView.vue?vue&type=template&id=1567ad56&scoped=true", function () {
      api.rerender('1567ad56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/grades/GradesAdminView.vue"
export default component.exports