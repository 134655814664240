



































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import { grades as gradesOptions } from '../../constants'
import StudentUtils from '@/utils/student-utils';
import { VForm } from 'vuetify/lib';
import { namespace } from 'vuex-class';

const grades = namespace('grades');
const students = namespace('students');
const settings = namespace('settings');

@Component
export default class PerformanceReport extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ required: false, default: null })
  selectedPeriod!: any;

  @grades.Action
  getPerformanceInfoAdmin!: (params?: any) => Promise<any>;

  @grades.Action
  getPerformanceInfo!: () => Promise<any>;

  @grades.Action
  getPerformanceReport!: (params?: any) => Promise<any>;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter
  getSettings!: any;

  @students.Action
  loadScoresForStudent!: (params?: any) => Promise<any>;

  @students.Getter
  getStudentAccountGradesData!: any;

  @settings.Getter('getSchools')
  schools!: any;

  @settings.Getter('getPrimarySchool')
  primarySchool!: any;

  levelFilter: any = null

  date = ''
  selectedStudent: any = null;
  selectedTeacher: any = null;
  selectedSubject: any = null;
  exportPeriod: any = '';
  attendanceBySubject = false;
  includeNotes = false;

  selectedTypes: any = [];
  selectedItems: any = [];

  studentListAdmin: any = [];
  studentListTeacher: any = [];
  periodListTeacher:any = [];
  teacherList: any = [];
  subjectList: any = [];
  typeList: any = [];

  types = [
    { title: this.$t('attendanceLabel'), field: 'gradeReportTypeMultiselect', code: 4 },
    { title: this.$t('gradesLabel'), field: 'gradeReportTypeMultiselect', code: 1 },
    { title: this.$t('standardsLabel'), field: 'gradeReportTypeMultiselect', code: 2 }
  ]

  itemList = [
    { title: this.$t('enteredLabel'), field: 'includeEntered', code: 'Y' },
    { title: this.$t('emptyLabel'), field: 'includeEmpty', code: 'Y' },
    { title: this.$t('excusedLabel'), field: 'includeCodes', code: 'E' },
    { title: this.$t('incompleteLabel'), field: 'includeCodes', code: 'I' },
    { title: this.$t('missingLabel'), field: 'includeCodes', code: 'M' },
    { title: this.$t('scoreNotesLabel'), field: 'includeNotes', code: 'Y' },
    { title: this.$t('schoolDaysLabel'), field: 'includeAttendanceSchoolDays', code: 'Y' },
    { title: this.$t('tardyLabel'), field: 'includeAttendanceTardy', code: 'Y' },
    { title: this.$t('absentLabel'), field: 'includeAttendanceAbsent', code: 'Y' }
  ]

  $refs!: {
    performanceReportForm: InstanceType<typeof VForm>
  }

  get haveMultipleSchools() {
    return this.schools.length > 1;
  }

  get getName() {
    return StudentUtils.getStudentLabel;
  }

  get gradesList(): any {
    return gradesOptions;
  }

  get isOpen() {
    return this.value;
  }

  set isOpen(val: boolean) {
    this.$emit('close');
  }

  get localRules() {
    return [(v: any) => !!v || this.$t('printSchoolStudentGradesErrorMsg1')];
  }

  get additionalAdminOptions() {
    return (this.userMode !== 'A' || this.selectedStudent);
  }

  get studentOptions() {
    if (this.userMode === 'A') {
      if (!this.levelFilter || parseInt(this.levelFilter.value) === -999) {
        return this.studentListAdmin;
      }
      return this.studentListAdmin.filter((s: any) => (s.gradeLevel === parseInt(this.levelFilter.value)));
    } else if (this.userMode === 'T') {
      return this.studentListTeacher;
    }
  }

  get periodOptions() {
    if (this.userMode === 'A') {
      if (this.selectedTeacher && this.selectedTeacher.teacherId !== 0) {
        return [{ name: this.$t('allPeriodsLabel'), gradePeriodId: 0 }].concat(this.selectedTeacher.gradePeriods);
      } else {
        return [{ name: this.$t('allPeriodsLabel'), gradePeriodId: 0 }];
      }
    } else if (this.userMode === 'T') {
      return this.periodListTeacher;
    } else {
      let associatedPeriods = [];
      if (this.getStudentAccountGradesData && this.selectedTeacher) {
        associatedPeriods = this.getStudentAccountGradesData.gradePeriods.filter((p : any) => p.teacherId === this.selectedTeacher.teacherId).map((p: any) => {
          p.name = p.gradePeriodName;
          return p;
        });
      }
      return [{ name: this.$t('allPeriodsLabel'), gradePeriodId: 0 }].concat(associatedPeriods);
    }
  }

  selectAllItems() {
    if (this.selectedItems.length === this.itemList.length) {
      this.selectedItems = [];
    } else {
      this.selectedItems = this.itemList;
    }
  }

  selectAllTypes() {
    if (this.selectedTypes.length === this.typeList.length) {
      this.selectedTypes = [];
    } else {
      this.selectedTypes = this.typeList;
    }
  }

  setAttendanceBySubject(val: boolean) {
    const viewingAttendance = (this.$refs as any)['item-' + this.$t('attendanceLabel')].$el.classList.contains('v-list-item--highlighted');
    if (viewingAttendance) {
      this.attendanceBySubject = val;
    }
  }

  // OPEN
  openPerformanceReportsAdmin() {
    this.date = DateTimeUtils.currentDateAddDays(0);
    this.levelFilter = this.gradesList[0];
    this.typeList = this.types;
    this.selectedTypes = this.typeList
  }

  @Watch('date')
  updateDate() {
    this.getPerformanceInfoAdmin({ gradeReportAsOf: this.date }).then((d: any) => {
      this.studentListAdmin = d.data.students.map((s: any) => {
        s.displayName = this.getName(s);
        return s;
      })
      CommonUtils.hideLoading();
    })
  }

  openPerformanceReportsTeacher() {
    CommonUtils.showLoading()
    this.periodListTeacher = [{ name: this.$t('allPeriodsLabel'), gradePeriodId: 0 }].concat(this.getSettings.accountSettings.gradingPeriods);
    this.exportPeriod = this.selectedPeriod ? this.selectedPeriod : this.periodListTeacher[0];
    this.typeList = this.types;
    this.selectedTypes = this.typeList;
    this.selectedItems = this.itemList;
    if (CommonUtils.isEmpty(this.studentListTeacher)) {
      this.getPerformanceInfo().then((d) => {
        this.studentListTeacher = [{ displayName: 'Students - All (' + d.data.studentCount + ')', type: 'All', id: '1' }];
        for (const i in d.data.classes) {
          this.studentListTeacher.push({ displayName: 'Students - ' + d.data.classes[i].className + ' (' + d.data.classes[i].numStudents + ')', type: 'Class', id: d.data.classes[i].cId || d.data.classes[i].classId })
        }
        for (const i in d.data.students) {
          this.studentListTeacher.push({ displayName: this.getName(d.data.students[i]), type: 'Student', id: d.data.students[i].studentId });
        }
        this.selectedStudent = this.studentListTeacher[0];
        CommonUtils.hideLoading();
      })
    }
    CommonUtils.hideLoading();
  }

  openPerformanceReportsStudent() {
    CommonUtils.showLoading();
    this.typeList = this.types.filter((t: any) => t.title !== 'Attendance');
    this.selectedTypes = this.typeList;
    this.teacherList = [{ displayName: this.$t('allTeachersLabel'), teacherId: 0 }].concat(this.getStudentAccountGradesData.teachers);
    this.selectedTeacher = this.teacherList[0];
    this.exportPeriod = this.selectedPeriod ? this.selectedPeriod : this.periodOptions[0];
    this.selectedSubject = this.subjectList[0];
    CommonUtils.hideLoading();
  }

  @Watch('value')
  onLoad() {
    if (this.value) {
      const form: any = this.$refs.performanceReportForm;
      if (form) form.resetValidation();
      if (this.userMode === 'A') {
        this.openPerformanceReportsAdmin();
      } else if (this.userMode === 'T') {
        this.openPerformanceReportsTeacher();
      } else {
        if (!this.getStudentAccountGradesData) {
          CommonUtils.showLoading();
          const payload:any = { studentId: this.getSettings.userId };
          if (this.$currentUser.isDistrictAdmin || this.haveMultipleSchools) {
            payload.currentSchoolId = (this.$store.state.teachers.currentSchoolId || this.primarySchool.schoolId);
          }
          this.loadScoresForStudent(payload).then(() => {
            this.openPerformanceReportsStudent();
          })
        } else {
          this.openPerformanceReportsStudent();
        }
      }
    }
  }

  // PRINT
  printReports() {
    const form: any = this.$refs.performanceReportForm;
    if (!form.validate()) {
      return;
    }
    if (this.userMode === 'A') {
      this.printReportsAdmin();
    } else if (this.userMode === 'T') {
      this.printReportsTeacher();
    } else {
      this.printReportsStudent();
    }
  }

  printReportsAdmin() {
    this.isOpen = false;
    this.getPerformanceReport({
      date: this.date,
      studentId: this.selectedStudent.studentId,
      teacherId: this.selectedTeacher.teacherId,
      classId: this.selectedSubject.subjectId,
      period: this.exportPeriod || { gradePeriodId: 0 },
      types: this.selectedTypes,
      bySubject: this.attendanceBySubject,
      includeNotes: this.includeNotes
    })
  }

  printReportsTeacher() {
    this.isOpen = false;
    this.getPerformanceReport({
      students: this.selectedStudent,
      period: this.exportPeriod,
      types: this.selectedTypes,
      items: this.selectedItems,
      bySubject: this.attendanceBySubject
    })
  }

  printReportsStudent() {
    this.isOpen = false;
    this.getPerformanceReport({
      teacherId: this.selectedTeacher.teacherId,
      classId: this.selectedSubject.subjectId,
      period: this.exportPeriod,
      types: this.selectedTypes,
      includeNotes: this.includeNotes
    })
  }

  // ADMIN UPDATES
  @Watch('levelFilter')
  clearLevel() {
    if (this.levelFilter && this.selectedStudent) {
      if (this.selectedStudent.gradeLevel !== this.levelFilter.value) {
        this.selectedStudent = null;
      }
    }
  }

  @Watch('selectedStudent')
  loadTeacherOptions() {
    if (this.userMode !== 'A') {
      return;
    }
    let associatedTeachers = [];
    if (this.selectedStudent) {
      associatedTeachers = this.selectedStudent.teachers.map((t: any) => {
        t.displayName = this.getName(t);
        return t;
      });
    }
    this.teacherList = [{ displayName: this.$t('allTeachersLabel'), teacherId: 0 }].concat(associatedTeachers);
    this.selectedTeacher = this.teacherList[0];
  }

  // ADMIN AND STUDENT
  @Watch('selectedTeacher')
  loadSubjectOptions() {
    if (this.userMode === 'A') {
      let associatedSubjects = []
      if (this.selectedTeacher.teacherId !== 0) {
        associatedSubjects = this.selectedTeacher.subjects.map((s: any) => {
          s.className = s.className || s.name;
          return s;
        });
      }
      this.subjectList = [{ className: this.$t('allSubjectsLabel'), subjectId: 0 }].concat(associatedSubjects);
      this.selectedSubject = this.subjectList[0];
      this.exportPeriod = this.selectedPeriod ? this.selectedPeriod : this.periodOptions[0];
    } else {
      let associatedSubjects = [];
      if (this.selectedTeacher && this.getStudentAccountGradesData.subjects) {
        associatedSubjects = this.getStudentAccountGradesData.subjects.filter((s: any) => (s.teacher.id === this.selectedTeacher.teacherId)).map((s: any) => {
          s.className = s.name;
          return s;
        });
      }
      this.subjectList = [{ className: this.$t('allSubjectsLabel'), subjectId: 0 }].concat(associatedSubjects);
      this.selectedSubject = this.subjectList[0];
      this.exportPeriod = this.selectedPeriod ? this.selectedPeriod : this.periodOptions[0];
    }
  }
}
