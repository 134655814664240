
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EditorSection from '@/components/common/EditorSection.vue';
import { VForm } from 'vuetify/lib';
import GradesTableTeacher from '@/components/grades/GradesTableTeacher.vue';
import GradesAdminView from '@/components/grades/GradesAdminView.vue';
import GradesTable from '@/components/grades/GradesTable.vue';
import PrintGrades from '@/components/grades/PrintGrades.vue';
import PerformanceReport from '@/components/grades/PerformanceReport.vue';
import ScoreWeightDialog from '@/components/classwork/ScoreWeightDialog.vue';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import Confirm from '../core/Confirm.vue';

const classes = namespace('classes');
const settings = namespace('settings');
const grades = namespace('grades');
const students = namespace('students');

@Component({
  mixins: [PageLifeCycleMixin],
  components: {
    EditorSection,
    GradesTableTeacher,
    GradesTable,
    GradesAdminView,
    PrintGrades,
    PerformanceReport,
    ScoreWeightDialog
  }
})
export default class Grades extends Vue {
    @classes.Getter('getClassItems')
    classList!: Array<any>;

    @settings.Getter
    getSettings!: any;

    @settings.Getter
    getCurrentSchoolYearId!: any;

    @settings.Getter('getBrowserWidth')
    browserWidth!: number;

    @grades.Action
    getPerformanceReport!: (params?: any) => Promise<any>;

    @students.Getter
    getStudents!: Array<any>;

    @students.Action
    loadStudentScores!: (params?: any) => Promise<any>;

    @settings.Getter('getSchools')
    schools!: any;

    @settings.Getter('getPrimarySchool')
    primarySchool!: any;

    showSlideGroup = true;
    selectedClass: any = null;
    selectedPeriod: any = {};
    periodList:Array<any> = [];
    localIsMenuOverlapping = false;
    lastBrowserWidth = 0;
    localSlideGroupModel = null;

    dialog = {
      scoreWeight: false,
      performanceReport: false,
      gradingPeriod: false,
      print: false
    }

    $refs!: {
      letterGradeForm: InstanceType<typeof VForm>,
      gradePeriodForm: InstanceType<typeof VForm>,
      gradesTable: GradesTableTeacher,
      studentGradesTable: GradesTable,
      adminGrades: Vue,
      printGrades: Vue,
      confirm: Confirm
    }

    @Watch('browserWidth')
    onBrowserWidthChanged(val:any) {
      this.isMenuOverlapping(val);
    }

    get currentSchoolId() {
      return this.$store.state.teachers.currentSchoolId || this.primarySchool.schoolId;
    }

    set currentSchoolId(val:any) {
      this.$store.commit('teachers/setCurrentSchoolId', val);
    }

    get schoolItems() {
      return this.schools.map((s: any) => {
        return { text: s.schoolName, value: +s.schoolId };
      });
    }

    get haveMultipleSchools() {
      return this.schools.length > 1;
    }

    get adminGradesRef() {
      return this.$refs.adminGrades;
    }

    get printGradesRef() {
      return this.$refs.printGrades;
    }

    get teacherGradesRef() {
      return this.$refs.gradesTable;
    }

    get studentGradesRef() {
      return this.$refs.studentGradesTable;
    }

    updateSlideGroup(val: any) {
      this.showSlideGroup = val;
    }

    created() {
      this.$nextTick(() => {
        this.initialize();
        if (!this.$currentUser.isAdmin) {
          this.$eventBus.$on('showLocalPrintOptions', () => {
            this.dialog.print = true;
          })
          this.$eventBus.$emit('printButtonShow');
        }
        this.$eventBus.$on('userModeChanged', this.initialize);
      })
    }

    initialize() {
      if (this.$currentUser.isTeacher) {
        this.loadClasses();
        if (this.classList.length === 0) {
          const confirm: any = this.$refs.confirm;
          confirm.alert({
            title: this.$t('gradesLabel'),
            text: this.$t('gradesPageDisabled'),
            option1ButtonAlternativeText: this.$t('okLabel')
          }).then(() => {
            this.$router.replace('/classes');
          })
        }
        this.periodList = [{ name: this.$t('allPeriodsLabel'), gradePeriodId: 0 }].concat(this.getSettings.accountSettings.gradingPeriods);
        this.selectedPeriod = this.periodList.find((p: any) => p.gradePeriodId === this.getSettings.accountSettings.currentGradingPeriodId);
      }
    }

    destroyed() {
      if (!this.$currentUser.isAdmin) {
        this.$eventBus.$emit('printButtonHide');
        this.$eventBus.$off('showLocalPrintOptions');
      }
      this.$eventBus.$off('userModeChanged', this.initialize);
    }

    loadClasses() {
      const storeSelectedClass = this.$store.state.grades.selectedClass;
      const isSelectedInClassList = this.classList.find((c) => c.value === storeSelectedClass.value);
      if (isSelectedInClassList && storeSelectedClass.value !== undefined) {
        this.selectedClass = storeSelectedClass;
      } else {
        this.selectedClass = this.classList[0];
      }
    }

    addClasswork() {
      this.$eventBus.$emit('openSubPage', {
        type: 'classwork',
        modal: true,
        input: {
          action: 'A',
          loadData: true,
          classId: this.selectedClass.value
        }
      });
    }

    activePeriod: any = {
      gradePeriodId: 0,
      yearId: 0,
      name: '',
      startDate: '',
      endDate: ''
    }

    openGradePeriod(item: any) {
      this.activePeriod = item;
      this.dialog.gradingPeriod = true;
    }

    isMenuOverlapping(browserWidth?:number) {
      if (this.lastBrowserWidth !== 0 && (browserWidth || 0) < this.lastBrowserWidth) {
        this.localIsMenuOverlapping = true;
        return;
      }
      if ((browserWidth || 0) > this.lastBrowserWidth) {
        this.localIsMenuOverlapping = false;
        this.lastBrowserWidth = 0;
        return;
      }
      setTimeout(() => {
        const toolbarTitle:any = document.getElementById('toolbarTitle');
        const titleRect = toolbarTitle.getBoundingClientRect();
        const elementToCheckIfOverlapping:any = document.getElementById('gradesFilterMenu');
        if (elementToCheckIfOverlapping) {
          const elementToCheckIfOverlappingRect = elementToCheckIfOverlapping.getBoundingClientRect();
          if (
            titleRect.top < elementToCheckIfOverlappingRect.bottom &&
            titleRect.bottom > elementToCheckIfOverlappingRect.top &&
            titleRect.left < elementToCheckIfOverlappingRect.right &&
            titleRect.right > elementToCheckIfOverlappingRect.left
          ) {
            this.lastBrowserWidth = this.browserWidth;
            this.localIsMenuOverlapping = true;
            return true;
          }
          this.localIsMenuOverlapping = false;
          return false;
        } else {
          this.localIsMenuOverlapping = false;
          return false;
        }
      }, 50);
      this.localIsMenuOverlapping = false;
      return false;
    }
}
