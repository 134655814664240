var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticStyle: { "margin-top": "12px" } },
    [
      _c(
        "v-card",
        { staticClass: "fill-height", attrs: { flat: "" } },
        [
          _c(
            "v-row",
            { staticClass: "d-flex flex-nowrap fill-height" },
            [
              !_vm.$vuetify.breakpoint.smAndDown ||
              _vm.individualStudent === null
                ? _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown
                        ? "student-list-mobile"
                        : "student-list"
                    },
                    [
                      _c(
                        "perfect-scrollbar",
                        {
                          staticClass: "d-flex flex-nowrap",
                          staticStyle: { "overflow-y": "auto" },
                          style: {
                            "max-height": _vm.$vuetify.breakpoint.smAndDown
                              ? "calc(100vh - 130px)"
                              : "calc(100vh - 90px)"
                          }
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.filteredStudents, function(student) {
                              return _c(
                                "v-list-item",
                                {
                                  key: student.studentId,
                                  staticClass: "px-2 focus-border",
                                  on: {
                                    click: function($event) {
                                      return _vm.loadStudent(student)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mt-1 mb-2",
                                      staticStyle: { width: "100%" },
                                      attrs: { elevation: "5" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "pa-2" },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              staticClass: "mr-5 white--text",
                                              staticStyle: {
                                                "font-weight": "lighter"
                                              },
                                              attrs: {
                                                color: "primary",
                                                size: "2rem"
                                              }
                                            },
                                            [
                                              _vm.hasText(student.photoUrl)
                                                ? _c("v-img", {
                                                    attrs: {
                                                      src: student.photoUrl
                                                    }
                                                  })
                                                : _c(
                                                    "span",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getInitials(
                                                            student
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "clickable body-2" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.getName(student))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$vuetify.breakpoint.smAndDown && _vm.individualStudent
                ? _c(
                    "div",
                    { staticClass: "mx-3 fill-width" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-2 mt-2 mb-2" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-0 pt-0 ml-0 pl-0",
                              attrs: { small: "", text: "" },
                              on: {
                                click: function($event) {
                                  _vm.individualStudent = null
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("fal fa-xmark")])],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-avatar",
                            {
                              staticClass: "mr-2 white--text",
                              staticStyle: { "font-weight": "lighter" },
                              attrs: { color: "primary", size: "2rem" }
                            },
                            [
                              _vm.hasText(_vm.individualStudent.photoUrl)
                                ? _c("v-img", {
                                    attrs: {
                                      src: _vm.individualStudent.photoUrl
                                    }
                                  })
                                : _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getInitials(_vm.individualStudent)
                                      )
                                    )
                                  ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-2 mt-2 font-weight-bold" },
                            [_vm._v(_vm._s(_vm.getName(_vm.individualStudent)))]
                          )
                        ],
                        1
                      ),
                      _c("grades-table", {
                        attrs: { studentId: _vm.individualStudent.studentId }
                      })
                    ],
                    1
                  )
                : _vm.individualStudent
                ? _c(
                    "perfect-scrollbar",
                    {
                      staticClass: "col fill-height",
                      staticStyle: {
                        "overflow-x": "auto",
                        "overflow-y": "auto",
                        "max-width": "calc(100vw - 383px)"
                      }
                    },
                    [
                      _c("div", { staticClass: "mb-4" }, [
                        _vm._v(_vm._s(_vm.getName(_vm.individualStudent)))
                      ]),
                      _c("grades-table", {
                        attrs: { studentId: _vm.individualStudent.studentId }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("performance-report", {
        attrs: { value: _vm.performanceReportDialog },
        on: {
          close: function($event) {
            _vm.performanceReportDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }