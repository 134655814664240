













































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { grades as gradesOptions } from '../../constants'
import StudentUtils from '@/utils/student-utils';
import GradesTable from './GradesTable.vue';
import PerformanceReport from './PerformanceReport.vue';
import CommonUtils from '@/utils/common-utils';
const students = namespace('students');
const settings = namespace('settings');
const teachers = namespace('teachers');

@Component({
  components: {
    GradesTable,
    PerformanceReport
  }
})
export default class GradesAdminView extends Vue {
  @students.Action
  loadStudents!: (params?: any) => Promise<any>;

  @students.Getter
  getStudents!: Array<any>;

  @settings.Getter('getPrimarySchool')
  primarySchool!: any;

  @settings.Getter('getSchools')
  schools!: any;

  @teachers.Action
  loadTeachers!: () => Promise<any>;

  students: Array<any> = [];
  search = '';
  showLevel: any = null;
  performanceReportDialog = false;
  localSlideGroupModel = null;

  get haveMultipleSchools() {
    return this.schools.length > 1;
  }

  get haveTeachers() {
    return this.$store.state.teachers.haveTeachers;
  }

  set haveTeachers(value: boolean) {
    this.$store.commit('teachers/setHaveTeachers', value);
  }

  get currentSchoolId() {
    return this.$store.state.teachers.currentSchoolId || this.primarySchool.schoolId;
  }

  set currentSchoolId(val:any) {
    this.$store.commit('teachers/setCurrentSchoolId', val);
  }

  get schoolItems() {
    return this.schools.map((s: any) => {
      return { text: s.schoolName, value: +s.schoolId };
    });
  }

  get gradesList(): any {
    return gradesOptions;
  }

  get getName() {
    return StudentUtils.getStudentLabel;
  }

  get getInitials() {
    return StudentUtils.getStudentInitials;
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get filteredStudents() {
    return this.students.filter((s: any) => {
      const correctGrade = (this.showLevel && this.showLevel.value !== '-999') ? (s.gradeLevel === parseInt(this.showLevel.value)) : true;
      return correctGrade && (s.firstName.toLowerCase().includes(this.search.toLowerCase()) || s.lastName.toLowerCase().includes(this.search.toLowerCase()))
    })
  }

  @Watch('currentSchoolId')
  onCurrentSchoolIdChanged() {
    if (this.haveTeachers) {
      this.haveTeachers = false;
      CommonUtils.showLoading();
      this.loadTeachers()
        .then(() => {
          return this.initialize();
        })
        .finally(() => {
          CommonUtils.hideLoading();
        });
    }
  }

  created() {
    this.initialize();
  }

  async initialize() {
    this.showLevel = this.gradesList[0];
    const params: any = { userMode: 'A' };
    if (this.$currentUser.isDistrictAdmin || this.haveMultipleSchools) {
      params.currentSchoolId = (this.$store.state.teachers.currentSchoolId || this.primarySchool.schoolId);
    }
    return this.loadStudents(params).then(() => {
      this.students = this.getStudents;
      return Promise.resolve();
    })
  }

  individualStudent: any = null;
  loadStudent(student: any) {
    this.individualStudent = student;
  }
}
