
import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DateTimeUtils from '@/utils/date-time-utils';
import StudentUtils from '@/utils/student-utils';
import moment from 'moment';

const settings = namespace('settings');
const students = namespace('students');
const classwork = namespace('classwork');
const grades = namespace('grades');

@Component
export default class GradesTableTeacher extends Vue {
    @Prop({ type: Object })
    selectedClass!: any;

    @Prop({ type: Number })
    selectedPeriod!: any;

    @Prop({ type: Boolean })
    localIsMenuOverlapping!: boolean;

    @settings.Getter
    getSettings!: any;

    @settings.Getter('getUserMode')
    userMode!: string;

    @settings.Getter('getGlobalFontSize')
    globalFontSize!: number;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    @students.Action
    loadStudentScores!: (params?: any) => Promise<any>;

    @students.Action
    loadStudents!: (param?: any) => Promise<any>;

    @students.Getter
    getStudents!: any[];

    @students.Getter
    getGradePeriods!: Array<any>;

    @students.Getter
    getAssignments!: any;

    @students.Getter
    getAssessments!: any;

    @students.Getter
    getStudentScores!: any;

    @classwork.Action
    getAssessment!: (params?: any) => Promise<any>;

    @classwork.Action
    getAssignment!: (params?: any) => Promise<any>;

    @grades.Action
    updateStudentScore!: (params?: any) => Promise<any>;

    @grades.Action
    getScoresForStudent!: (params?: any) => Promise<any>;

    @grades.Action
    addNote!: (params?: any) => Promise<any>;

    @grades.Action
    addCustomScoreWeight!: (params?: any) => Promise<any>;

    @grades.Action
    overrideScore!: (params?: any) => Promise<any>;

    @grades.Action
    updateStandardScore!: (params?: any) => Promise<any>;

    studentAssignments: any;
    studentAssessments: any;
    studentScores: any;
    error = '';
    overallHeader: any = [];
    hasGoogleGrades = false;
    fullYearMode = true;
    singleStudent = false;
    periodHeaders: any = [];
    classworkHeaders: any = [];
    filteredClassworkHeaders: any = [];
    studentData: any = [];
    allStudents: any = [];
    isLoading = false;
    itemFocused = '';

    getStudentLabel(student: any) {
      return StudentUtils.getStudentLabel(student);
    }

    scrollSync(position: number) {
      const scrollS: any = this.$refs.scrollStudents;
      const scrollG: any = this.$refs.scrollGrades;
      if (position) {
        scrollS.scrollTop = scrollG.scrollTop;
      } else {
        scrollG.scrollTop = scrollS.scrollTop;
      }
    }

    scrollSyncHorizontal(position: number) {
      const scrollH: any = this.$refs.scrollHeaders;
      const scrollG: any = this.$refs.scrollGrades;
      if (position) {
        scrollH.scrollLeft = scrollG.scrollLeft;
      } else {
        scrollG.scrollLeft = scrollH.scrollLeft;
      }
    }

    get singleStudentScrollheight() {
      let marginUsed = 220;
      if (this.hasGoogleGrades) marginUsed += 40;
      if (this.$vuetify.breakpoint.smAndDown) marginUsed += 40;
      if (this.$vuetify.breakpoint.smAndDown || this.localIsMenuOverlapping) marginUsed += 40;
      return `calc(100vh - ${marginUsed}px)`;
    }

    get categoryFontSize() {
      return (this.globalFontSize - 1).toString() + 'px';
    }

    // SHOW ASSIGNMENTS / ASSESSMENTS
    showAssignments = 0;
    showAssessments = 0;
    areAssessments = true;
    areAssignments = true;
    assignmentIndex = -1;
    assessmentIndex = -1;
    assessmentHeaders = [];
    assignmentHeaders = [];

    // GET DATA
    created() {
      this.$nextTick(() => {
        this.$eventBus.$on('refreshClasswork', () => {
          this.refreshTable();
        });
        this.loadStudents({ userMode: this.userMode }).then(() => {
          this.allStudents = this.getStudents
          this.refreshTable();
        })
      })
    }

    destroyed() {
      this.$eventBus.$off('refreshClasswork');
    }

    @Watch('selectedClass')
    @Watch('selectedPeriod')
    refreshTable() {
      this.$nextTick(() => {
        CommonUtils.showLoading();
        this.fullYearMode = (this.selectedPeriod || 0) === 0;
        if (this.singleStudent) {
          this.loadSingleStudent(this.currentStudentData.studentId);
        } else {
          this.showAssessments = 0;
          this.showAssignments = 0;
          this.loadDataTable();
        }
      })
    }

    @Watch('showStandards')
    toggleStandards() {
      if (this.singleStudent) {
        this.filterTablesStandards();
      }
    }

    // UTILITY FUNCTIONS
    callTabComplex(event: any, rowIdx: any, colIdx: any, stdIxd: any) {
      if (event.shiftKey) {
        this.tabBackwardsComplex(rowIdx, colIdx, stdIxd);
      } else {
        this.tabForwardComplex(rowIdx, colIdx, stdIxd);
      }
    }

    tabBackwardsComplex(rowIdx: any, colIdx: any, stdIxd: any) {
      let prevElement: any = this.$refs['tabIDXr' + (rowIdx - 1) + 'c' + colIdx + 's' + stdIxd];
      const maxRowIdx = this.sortedStudentData.length - 1;
      if (!prevElement && this.showStandards && stdIxd > 0) {
        prevElement = this.$refs['tabIDXr' + maxRowIdx + 'c' + colIdx + 's' + (stdIxd - 1)];
      }
      if (!prevElement) {
        let maxStandarIdx = 0;
        if (this.showStandards) {
          const classworkIdx = (colIdx - 1) - (this.fullYearMode ? 1 : 0) - this.periodHeaders.length;
          if (classworkIdx >= 0) {
            maxStandarIdx = this.filteredClassworkHeaders[classworkIdx].standards.length;
          }
        }
        prevElement = this.$refs['tabIDXr' + maxRowIdx + 'c' + (colIdx - 1) + 's' + maxStandarIdx];
      }
      if (!prevElement) {
        prevElement = this.$refs['headerC' + (this.filteredClassworkHeaders.length - 1)];
      }
      if (!prevElement) {
        prevElement = this.$refs['headerP' + (this.periodHeaders.length - 1)];
      }
      if (prevElement) {
        try {
          prevElement[0].focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      }
    }

    tabForwardComplex(rowIdx: any, colIdx: any, stdIxd: any) {
      let nextElement: any = this.$refs['tabIDXr' + (rowIdx + 1) + 'c' + colIdx + 's' + stdIxd];
      const classworkIdx = colIdx - (this.fullYearMode ? 1 : 0) - this.periodHeaders.length;
      if (!nextElement && this.showStandards && classworkIdx >= 0) {
        nextElement = this.$refs['tabIDXr0c' + colIdx + 's' + (stdIxd + 1)]
      }
      if (!nextElement) {
        nextElement = this.$refs['tabIDXr0c' + (colIdx + 1) + 's0']
      }
      if (nextElement) {
        try {
          nextElement[0].focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      }
    }

    callTab(event: any, curIndex: number) {
      let nextElement: any = null;
      if (event.shiftKey) {
        nextElement = this.$refs['tabIDX' + (curIndex - 1)]
        if (!nextElement) {
          nextElement = this.$refs.percentageField;
        }
      } else {
        nextElement = this.$refs['tabIDX' + (curIndex + 1)]
      }
      if (nextElement) {
        nextElement.focus();
      }
    }

    getStatusClass(status: string) {
      if (status === 'PENDING') {
        return 'inputPending'
      } else if (status === 'SUCCESS') {
        return 'inputSuccess'
      } else if (status === 'FAILED') {
        return 'inputFailed'
      } else {
        return null;
      }
    }

    getAppendIcon(status: string) {
      if (status === 'PENDING') {
        return 'fa-solid fa-spinner'
      } else if (status === 'FAILED') {
        return 'fa-solid fa-exclamation'
      } else {
        return '';
      }
    }

    getLetterGrade(percentage: number) {
      const letterGrades = this.getSettings.accountSettings.letterGrades;
      for (const i in letterGrades) {
        if (percentage >= letterGrades[i].percentage) {
          return letterGrades[i].name;
        }
      }
      return '';
    }

    formatDateYearless(date: any) {
      const dateToDisplay = `${moment(date, 'MM/DD/YYYY').format('MMM DD')}`;
      return dateToDisplay !== 'Invalid date' ? dateToDisplay : '';
    }

    get formatDate() {
      return DateTimeUtils.formatToShow
    }

    truncateName(studentName: string) {
      if (studentName.length > 29) {
        return studentName.slice(0, 26) + '...';
      } else {
        return studentName;
      }
    }

    truncateTitle(classworkTitle: string) {
      if (classworkTitle.length > 37) {
        return classworkTitle.slice(0, 34) + '...';
      } else {
        return classworkTitle
      }
    }

    getInitials(student: any) {
      return StudentUtils.getStudentInitials(student);
    }

    // GENERAL UPDATES
    saveStandardWrapper(standardInfo: any, studentInfo: any, type: string) {
      if (standardInfo.code === standardInfo.codeCopy) {
        return;
      }
      standardInfo.codeCopy = standardInfo.code;
      const selectedWeight = this.getSettings.accountSettings.standardWeights.find((s: any) => s.code === standardInfo.code)
      if (!selectedWeight && CommonUtils.hasText(standardInfo.code)) {
        Vue.set(standardInfo, 'status', 'FAILED');
        return;
      }
      Vue.set(standardInfo, 'status', 'PENDING');
      const payload: any = {
        standardScoreId: standardInfo.standardScoreId || standardInfo.scoreId,
        standardDBId: standardInfo.standardDBId,
        studentId: studentInfo.studentId,
        assignmentId: standardInfo.assignmentId,
        assessmentId: standardInfo.assessmentId,
        standardScoreWeightId: selectedWeight ? selectedWeight.standardScoreWeightId : 0,
        type: type
      }

      this.updateStandardScore(payload).then((d) => {
        if (!d.data.scoreOK) {
          Vue.set(standardInfo, 'status', 'FAILED');
        } else {
          Vue.set(standardInfo, 'status', 'SUCCESS');
          standardInfo.standardScoreId = d.data.standardScores.standardScoreId;
          standardInfo.standardScoreWeightId = d.data.standardScores.standardScoreWeightId;
        }
      }).catch(() => {
        Vue.set(standardInfo, 'status', 'FAILED');
      })
    }

    overridePercentageWrapper(studentInfo: any) {
      if (studentInfo.percentage === studentInfo.percentageCopy) {
        return;
      }

      Vue.set(studentInfo, 'percentageStatus', 'PENDING');
      const payload: any = {
        studentId: studentInfo.studentId,
        subjectId: this.selectedClass.value,
        gradePeriodId: 0,
        overrideScoreId: studentInfo.overrideScoreId,
        percentage: studentInfo.percentage
      }
      if (studentInfo.source === 'gc') {
        payload.sourceStudentId = studentInfo.sourceId;
      }

      this.overrideScore(payload).then((d) => {
        Vue.set(studentInfo, 'percentageStatus', 'SUCCESS');
        studentInfo.percentage = d.data.percentage;
        studentInfo.percentageCopy = d.data.percentage;
        studentInfo.overrideScoreId = d.data.overrideScoreId
        studentInfo.letterGrade = d.data.letterGrade;

        if (!this.singleStudent) {
          this.overallHeader.average = this.getOverallAverage();
        }

        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg44') as string);
        }

        return Promise.resolve();
      }).catch(() => {
        Vue.set(studentInfo, 'percentageStatus', 'FAILED');
      })
    }

    overridePeriodWrapper(studentInfo: any, periodInfo: any, periodId: number) {
      if (periodInfo.percentage === periodInfo.percentageCopy) {
        return;
      }

      Vue.set(periodInfo, 'percentageStatus', 'PENDING');
      const payload = {
        studentId: studentInfo.studentId,
        subjectId: this.selectedClass.value,
        gradePeriodId: periodId,
        overrideScoreId: periodInfo.overrideScoreId,
        percentage: periodInfo.percentage
      }

      this.overrideScore(payload).then((d) => {
        // changes this period
        const periodResponse = d.data.gradePeriodScores.find((p: any) => p.gradePeriodId === periodId);
        periodInfo.percentage = periodResponse.percentage
        periodInfo.percentageCopy = periodResponse.percentage
        periodInfo.overrideScoreId = periodResponse.overrideScoreId
        periodInfo.letterGrade = periodResponse.letterGrade

        if (!this.singleStudent) {
          // changes overall grade
          studentInfo.percentage = d.data.percentage;
          studentInfo.percentageCopy = d.data.percentage;
          studentInfo.overrideScoreId = d.data.overrideScoreId
          studentInfo.letterGrade = d.data.letterGrade;
          // changes overall average
          this.overallHeader.average = this.getOverallAverage();
          // changes this period average
          this.periodHeaders.find((p: any) => p.gradePeriodId === periodId).average = this.getPeriodAverage(periodId);
        }
        Vue.set(periodInfo, 'percentageStatus', 'SUCCESS');

        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg44') as string);
        }

        return Promise.resolve();
      }).catch(() => {
        Vue.set(periodInfo, 'percentageStatus', 'FAILED');
      })
    }

    // EDIT HEADERS
    editClasswork(classworkType: any, classwork: any, source: any) {
      if (source === 'gc') {
        this.$eventBus.$emit('openSubPage', {
          type: 'classwork',
          modal: true,
          input: {
            action: 'G',
            loadData: true,
            classwork: classwork
          }
        });
      } else if (classworkType === 'm') {
        this.getAssessment({ assessmentId: classwork.id }).then((d) => {
          d.resp.data.isAssignment = false;
          this.$eventBus.$emit('openSubPage', {
            type: 'classwork',
            modal: true,
            input: {
              action: 'E',
              loadData: true,
              classwork: d.resp.data
            }
          });
        })
      } else {
        this.getAssignment({ assignmentId: classwork.id }).then((d) => {
          d.resp.data.isAssignment = true;
          this.$eventBus.$emit('openSubPage', {
            type: 'classwork',
            modal: true,
            input: {
              action: 'E',
              loadData: true,
              classwork: d.resp.data
            }
          });
        })
      }
    }

    openGoogleGrade(rowData: any, classwork: any) {
      classwork.sourceScoreAlternateLink = rowData[classwork.id].sourceScoreAlternateLink
      this.$eventBus.$emit('openSubPage', {
        type: 'classwork',
        modal: true,
        input: {
          action: 'GG',
          loadData: true,
          classwork: classwork
        }
      });
    }

    openGoogleGradeSingleStudent(classwork: any) {
      console.log(classwork.sourceScoreAlternateLink)
      this.$eventBus.$emit('openSubPage', {
        type: 'classwork',
        modal: true,
        input: {
          action: 'GG',
          loadData: true,
          classwork: classwork
        }
      });
    }

    changeCategoryGoogle(classwork: any, selectedScoreWeight: any) {
      const payload = {
        sourceId: classwork.sourceId,
        subjectId: this.selectedClass.value,
        scoreWeightId: selectedScoreWeight.scoreWeightId
      }

      this.addCustomScoreWeight(payload).then(() => this.refreshTable());
    }

    get sortedStudentData() {
      return this.studentData.sort((a: any, b: any) => {
        const fa = a.studentName.toLowerCase()
        const fb = b.studentName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    }

    get assignmentCategories() {
      let categoryOptions = [{ scoreWeightId: 0, name: this.$t('noCategoryLabel') }]
      const classTypes = this.getSettings.accountSettings.scoreWeights.filter((a: any) => a.type === 'g' && a.subjectId === this.selectedClass.value)
      if (CommonUtils.isEmpty(classTypes)) {
        categoryOptions = categoryOptions.concat(this.getSettings.accountSettings.scoreWeights.filter((a: any) => a.type === 'g' && a.subjectId === 0));
      } else {
        categoryOptions = categoryOptions.concat(classTypes);
      }
      return categoryOptions;
    }

    get assessmentCategories() {
      let categoryOptions = [{ scoreWeightId: 0, name: this.$t('noCategoryLabel') }]
      const classTypes = this.getSettings.accountSettings.scoreWeights.filter((a: any) => a.type === 'm' && a.subjectId === this.selectedClass.value)
      if (CommonUtils.isEmpty(classTypes)) {
        categoryOptions = categoryOptions.concat(this.getSettings.accountSettings.scoreWeights.filter((a: any) => a.type === 'm' && a.subjectId === 0));
      } else {
        categoryOptions = categoryOptions.concat(classTypes);
      }
      return categoryOptions;
    }

    get ascendingDateOrder() {
      return this.$store.state.grades.ascendingDateOrder;
    }

    set ascendingDateOrder(val) {
      this.$store.commit('grades/setAscendingDateOrder', val);
    }

    get showStandards() {
      return this.$store.state.grades.showStandards;
    }

    set showStandards(val) {
      this.$store.commit('grades/setShowStandards', val);
    }

    // MOBILE VIEW FUNCTIONS
    mobileHeaderItems: Array<any> = [];
    viewItem: any = null;

    @Watch('viewItem')
    showSlideGroup() {
      this.$emit('showSlideGroup', this.viewItem === null);
    }

    get filteredMobileItems() {
      return this.mobileHeaderItems.filter((i: any) => {
        if (i.value === 'OA' && !this.fullYearMode) {
          return false;
        } else if (i.type === 'm' && this.showAssessments !== 0) {
          return false;
        } else if (i.type === 'g' && this.showAssignments !== 0) {
          return false;
        } else if (i.value === 'S' && !this.showStandards) {
          return false;
        }
        return true;
      })
    }

    loadMobileHeaderItems() {
      this.mobileHeaderItems = [];
      this.mobileHeaderItems = this.mobileHeaderItems.concat(this.overallHeader);
      this.mobileHeaderItems = this.mobileHeaderItems.concat(this.periodHeaders);

      for (const i in this.classworkHeaders) {
        const classwork = this.classworkHeaders[i];
        classwork.reporting = this.getClassworkAverage(this.classworkHeaders[i].id).reporting;
        this.mobileHeaderItems.push(classwork)
        if (classwork.standards.length > 0) {
          const standardItems = classwork.standards.map((s: any) => {
            s.classwork = classwork;
            s.dueDate = classwork.dueDate;
            s.type = classwork.type;
            s.classworkId = classwork.id;
            s.text = s.id;
            s.value = 'S';
            s.group = 'Standard';
            s.reporting = this.getStandardReporting(this.classworkHeaders[i].id, s.dbId);
            return s;
          })
          this.mobileHeaderItems = this.mobileHeaderItems.concat(standardItems);
        }
      }
    }

    mobileHeaders = [{ text: '', value: 'date', width: '20', sortable: false },
      { text: '', value: 'title', width: '40', sortable: false },
      { text: '', value: 'average', width: '40', sortable: false },
      { text: '', value: 'action', width: '20', sortable: false }]

    mobileHeadersItem = [{ text: '', value: 'student', width: '60', sortable: false },
      { text: '', value: 'letterGrade', width: '20', sortable: false },
      { text: '', value: 'grade', width: '20', sortable: false }]

    mobileHeadersStudent = [];
    localItemHovered = '';

    // LOAD MULTI-STUDENT TABLE
    loadDataTable() {
      if (!this.selectedClass) {
        CommonUtils.hideLoading();
        return;
      }
      CommonUtils.showLoading();
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.loadStudentScores({ subjectId: this.selectedClass.value, gradePeriodId: this.selectedPeriod }).then(() => {
        this.studentAssessments = this.getAssessments;
        this.studentAssignments = this.getAssignments;
        this.studentScores = this.getStudentScores;

        // MAKE TABLE
        this.generateErrorMessage();
        if (CommonUtils.hasNoText(this.error)) {
          this.loadHeaders();
          this.loadStudentInTable();
          this.loadAverages();
          CommonUtils.hideLoading();
          if (this.$vuetify.breakpoint.smAndDown) {
            this.loadMobileHeaderItems()
          }
        }
        this.isLoading = false;
      })
    }

    generateErrorMessage() {
      if (!this.studentScores) {
        this.error = this.$t('noStudentsError') as string;
        CommonUtils.hideLoading();
      } else if (!this.studentAssessments && !this.studentAssignments) {
        this.error = this.$t('noClassworkError') as string;
        CommonUtils.hideLoading();
      } else {
        this.error = '';
      }
    }

    loadHeaders() {
      this.overallHeader = { value: 'OA', id: 'overall', text: this.$t('overallLabel'), average: '' };
      this.periodHeaders = this.getGradePeriods.map((p: any) => {
        p.value = 'GP';
        p.id = p.gradePeriodId;
        p.text = p.name;
        p.average = '';
        return p;
      });
      if (!this.fullYearMode) {
        this.periodHeaders = this.periodHeaders.filter((p: any) => {
          return p.gradePeriodId === this.selectedPeriod;
        })
      }

      if (this.studentAssessments) {
        this.areAssessments = true;
        this.assessmentHeaders = this.studentAssessments.map((c: any) => {
          c.text = c.assessmentTitle;
          c.group = c.scoreWeightName || this.$t('noCategoryLabel');
          c.value = c.assessmentId.toString();
          c.dueDate = c.assessmentEnd;
          c.id = c.assessmentId;
          c.source = c.source || '';
          c.link = c.alternateLink || '';
          c.average = '';
          c.type = 'm';
          if (c.source === 'gc') {
            this.hasGoogleGrades = true;
            c.scoreWeightItem = this.assessmentCategories.find((ac: any) => ac.scoreWeightId === c.scoreWeightId)
          }
          if (c.gradePeriod) {
            c.gradePeriodId = c.gradePeriod.gradePeriodId;
          } else {
            c.gradePeriodId = 0;
          }
          return c;
        })
      } else {
        this.assessmentHeaders = [];
        this.areAssessments = false;
        this.showAssessments = 1;
      }

      if (this.studentAssignments) {
        this.areAssignments = true;
        this.assignmentHeaders = this.studentAssignments.map((c: any) => {
          c.text = c.assignmentTitle;
          c.group = c.scoreWeightName || this.$t('noCategoryLabel');
          c.value = c.assignmentId.toString();
          c.dueDate = c.assignmentEnd;
          c.id = c.assignmentId;
          c.source = c.source || '';
          c.link = c.alternateLink || '';
          c.average = '';
          c.type = 'g';
          if (c.source === 'gc') {
            this.hasGoogleGrades = true;
            c.scoreWeightItem = this.assignmentCategories.find((ac: any) => ac.scoreWeightId === c.scoreWeightId)
          }
          if (c.gradePeriod) {
            c.gradePeriodId = c.gradePeriod.gradePeriodId;
          } else {
            c.gradePeriodId = 0;
          }
          return c;
        })
      } else {
        this.assignmentHeaders = [];
        this.areAssignments = false;
        this.showAssignments = 1;
      }

      // sort in date order
      this.assessmentHeaders = this.assessmentHeaders.sort(this.sortHeaders)
      this.assignmentHeaders = this.assignmentHeaders.sort(this.sortHeaders);
      this.classworkHeaders = this.assessmentHeaders.concat(this.assignmentHeaders);
      this.classworkHeaders = this.classworkHeaders.sort(this.sortHeaders);
      this.filteredClassworkHeaders = this.classworkHeaders;
    }

    sortHeaders(a: any, b: any) {
      if (!a.dueDate && !b.dueDate) {
        return 0;
      } else if (!a.dueDate) {
        return 1;
      } else if (!b.dueDate) {
        return -1;
      } else if (DateTimeUtils.isThisDateBeforeThatDate(a.dueDate, b.dueDate)) {
        return this.ascendingDateOrder ? -1 : 1;
      } else if (DateTimeUtils.isThisDateBeforeThatDate(b.dueDate, a.dueDate)) {
        return this.ascendingDateOrder ? 1 : -1;
      } else {
        return 0;
      }
    }

    loadAverages() {
      this.overallHeader.average = this.getOverallAverage();
      for (const i in this.periodHeaders) {
        this.periodHeaders[i].average = this.getPeriodAverage(this.periodHeaders[i].gradePeriodId);
      }
      for (const i in this.classworkHeaders) {
        this.classworkHeaders[i].average = this.getClassworkAverage(this.classworkHeaders[i].id).average;
      }
    }

    getOverallAverage() {
      let sum = 0;
      let count = 0;
      for (const i in this.studentData) {
        const score = this.studentData[i].percentage;
        if (typeof score === 'number' || CommonUtils.hasText(score)) {
          sum += (+score);
          count++;
        }
      }
      const averavge = (sum / count).toFixed(1);
      return averavge;
    }

    getPeriodAverage(gradePeriodId: number) {
      let sum = 0;
      let count = 0;
      for (const i in this.studentData) {
        const score = this.studentData[i].periods[gradePeriodId].percentage;
        if (typeof score === 'number' || CommonUtils.hasText(score)) {
          sum += (+score);
          count++;
        }
      }
      const averavge = (sum / count).toFixed(1);
      return averavge;
    }

    getClassworkAverage(classworkId: number) {
      let sum = 0;
      let count = 0;
      for (const i in this.studentData) {
        const score = this.studentData[i][classworkId].score;
        if (!isNaN(score) && (typeof score === 'number' || CommonUtils.hasText(score))) {
          sum += score;
          count++;
        }
      }
      const reporting = (count / this.studentData.length * 100).toFixed();
      const averavge = (sum / count).toFixed(1);
      return { reporting: reporting, average: averavge };
    }

    getStandardReporting(classworkId: number, standardId: number) {
      let count = 0;
      for (const i in this.studentData) {
        const standardScore = this.studentData[i][classworkId].standards[standardId].code;
        if (CommonUtils.hasText(standardScore)) {
          count++;
        }
      }
      return (count / this.studentData.length * 100).toFixed();
    }

    loadStudentInTable() {
      this.studentData = [];
      for (const i in this.studentScores) {
        const curStudent = this.studentScores[i];
        const studentRow: any = {
          studentName: this.getStudentLabel(curStudent),
          studentId: curStudent.studentId,
          percentage: curStudent.percentage,
          percentageCopy: curStudent.percentage,
          overrideScoreId: curStudent.overrideScoreId,
          letterGrade: curStudent.letterGrade,
          source: curStudent.source || '',
          sourceId: curStudent.sourceId || '',
          initials: this.getInitials(curStudent),
          periods: {}
        };
        const ac = this.allStudents.find((s: any) => s.studentId === curStudent.studentId);
        if (ac) {
          studentRow.photoUrl = ac.photoUrl || '';
        }

        for (const k in curStudent.gradePeriodScores) {
          studentRow.periods[curStudent.gradePeriodScores[k].gradePeriodId] = {
            percentage: curStudent.gradePeriodScores[k].percentage,
            percentageCopy: curStudent.gradePeriodScores[k].percentage,
            overrideScoreId: curStudent.gradePeriodScores[k].overrideScoreId,
            letterGrade: curStudent.gradePeriodScores[k].letterGrade
          }
        }

        for (const j in this.classworkHeaders) {
          const columnName = this.classworkHeaders[j].value;
          let associatedScore;
          if (this.classworkHeaders[j].type === 'm') {
            associatedScore = curStudent.assessmentScores.filter((s: any) => s.assessmentId === this.classworkHeaders[j].id)[0]
            if (associatedScore && associatedScore.code) {
              studentRow[columnName] = { score: associatedScore.code, scoreCopy: associatedScore.code, scoreId: associatedScore.scoreId };
            } else if (associatedScore) {
              studentRow[columnName] = { score: associatedScore.totalPoints, scoreCopy: associatedScore.totalPoints, scoreId: associatedScore.scoreId || 0 };
            } else {
              studentRow[columnName] = { score: '', scoreCopy: '', scoreId: 0 };
            }
          } else {
            associatedScore = curStudent.assignmentScores.filter((s: any) => s.assignmentId === this.classworkHeaders[j].id)[0];
            if (associatedScore && associatedScore.code) {
              studentRow[columnName] = { score: associatedScore.code, scoreCopy: associatedScore.code, scoreId: associatedScore.scoreId };
            } else if (associatedScore) {
              studentRow[columnName] = { score: associatedScore.totalPoints, scoreCopy: associatedScore.totalPoints, scoreId: associatedScore.scoreId || 0 };
            } else {
              studentRow[columnName] = { score: '', scoreCopy: '', scoreId: 0 };
            }
          }
          studentRow[columnName].sourceScoreAlternateLink = associatedScore.sourceScoreAlternateLink;

          studentRow[columnName].standards = {}
          for (const s in this.classworkHeaders[j].standards) {
            const standardId = this.classworkHeaders[j].standards[s].dbId
            const associatedData = associatedScore ? associatedScore.standardScores.find((s: any) => s.standardDBId === standardId) : {};
            associatedData.code = associatedData.code || '';
            associatedData.codeCopy = associatedData.code || '';
            studentRow[columnName].standards[standardId] = associatedData;
          }
        }
        this.studentData.push(studentRow)
      }
      CommonUtils.hideLoading();
    }

    // UPDATES MULTI-STUDENT VIEW
    saveGrade(rowData: any, classwork: any) {
      if (rowData[classwork.id].score === rowData[classwork.id].scoreCopy) {
        return;
      }

      Vue.set(rowData[classwork.id], 'status', 'PENDING');
      const payload: any = {
        gradePeriodId: classwork.gradePeriodId,
        subjectId: this.selectedClass.value,
        scoreId: rowData[classwork.id].scoreId,
        studentId: rowData.studentId,
        totalPoints: rowData[classwork.id].score,
        type: classwork.type
      }

      if (classwork.type === 'm') {
        payload.assessmentId = classwork.id;
        payload.assignmentId = 0;
      } else {
        payload.assessmentId = 0;
        payload.assignmentId = classwork.id;
      }
      if (classwork.source === 'gc') {
        payload.sourceId = classwork.sourceId;
      }
      if (rowData.source === 'gc') {
        payload.sourceStudentId = rowData.sourceId;
      }

      this.updateStudentScore(payload).then((d) => {
        // changes this score
        rowData[classwork.id].score = d.data.studentScore.totalPoints;
        if (rowData[classwork.id].score === -1) {
          rowData[classwork.id].score = '';
        } else if (d.data.studentScore.code) {
          rowData[classwork.id].score = d.data.studentScore.code
        }
        rowData[classwork.id].scoreCopy = rowData[classwork.id].score
        rowData[classwork.id].scoreId = d.data.studentScore.scoreId
        // changes this classwork average
        classwork.average = this.getClassworkAverage(classwork.id).average;
        // changes grade period
        if (classwork.gradePeriodId !== 0) {
          const associatedPeriod = d.data.gradePeriodScores.find((p: any) => p.gradePeriodId === classwork.gradePeriodId);
          // this score
          rowData.periods[classwork.gradePeriodId].percentage = associatedPeriod.percentage
          rowData.periods[classwork.gradePeriodId].percentageCopy = associatedPeriod.percentage
          rowData.periods[classwork.gradePeriodId].letterGrade = associatedPeriod.letterGrade
          // average
          const associatedPeriodHeader = this.periodHeaders.find((p: any) => p.gradePeriodId === classwork.gradePeriodId)
          associatedPeriodHeader.average = this.getPeriodAverage(classwork.gradePeriodId);
        }
        // changes student's overall
        rowData.percentage = d.data.percentage
        rowData.percentageCopy = d.data.percentage
        rowData.letterGrade = d.data.letterGrade
        // changes overall average
        this.overallHeader.average = this.getOverallAverage();
        Vue.set(rowData[classwork.id], 'status', 'SUCCESS');

        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg44') as string);
        }

        return Promise.resolve();
      }).catch(() => {
        Vue.set(rowData[classwork.id], 'status', 'FAILED');
      })
    }

    reverseDateOrder() {
      this.ascendingDateOrder = !this.ascendingDateOrder;
      this.filteredClassworkHeaders = this.filteredClassworkHeaders.toReversed();
    }

    allAssignments() {
      if (this.showAssignments !== 0) {
        this.hideAssignments();
        this.assignmentIndex = -1;
        this.filteredClassworkHeaders = this.filteredClassworkHeaders.concat(this.assignmentHeaders);
        this.filteredClassworkHeaders = this.filteredClassworkHeaders.sort(this.sortHeaders);
      }
    }

    allAssessments() {
      if (this.showAssessments !== 0) {
        this.hideAssessments();
        this.assessmentIndex = -1;
        this.filteredClassworkHeaders = this.filteredClassworkHeaders.concat(this.assessmentHeaders);
        this.filteredClassworkHeaders = this.filteredClassworkHeaders.sort(this.sortHeaders);
      }
    }

    hideAssignments() {
      this.assignmentIndex = -1;
      this.filteredClassworkHeaders = this.filteredClassworkHeaders.filter((c: any) => c.type !== 'g') || [];
    }

    hideAssessments() {
      this.assessmentIndex = -1;
      this.filteredClassworkHeaders = this.filteredClassworkHeaders.filter((c: any) => c.type !== 'm') || []
    }

    traverseAssignment(index: number) {
      if (this.showAssessments !== 1) {
        this.hideAssessments();
        this.showAssessments = 1;
      }
      if (this.assignmentIndex === -1) {
        this.assignmentIndex = 0
      } else {
        this.assignmentIndex += index;
      }

      // wrap
      if (this.assignmentIndex === -1) {
        this.assignmentIndex = this.assignmentHeaders.length - 1;
      } else if (this.assignmentIndex === this.assignmentHeaders.length) {
        this.assignmentIndex = 0;
      }

      // select
      this.filteredClassworkHeaders = [this.assignmentHeaders[this.assignmentIndex]];
    }

    traverseAssessment(index: number) {
      if (this.showAssignments !== 1) {
        this.hideAssignments();
        this.showAssignments = 1;
      }
      if (this.assessmentIndex === -1) {
        this.assessmentIndex = 0
      } else {
        this.assessmentIndex += index;
      }

      // wrap
      if (this.assessmentIndex === -1) {
        this.assessmentIndex = this.assessmentHeaders.length - 1;
      } else if (this.assessmentIndex === this.assessmentHeaders.length) {
        this.assessmentIndex = 0;
      }

      // select
      this.filteredClassworkHeaders = [this.assessmentHeaders[this.assessmentIndex]];
    }

    // SINGLE STUDENT MODE
    currentStudentData: any = {};
    headers = [
      { text: this.$t('titleLabel'), value: 'title', width: 80, sortable: false, divider: true },
      { text: this.$t('typeLabel'), value: 'scoreWeightName', width: 120, sortable: false, divider: true },
      { text: this.$t('dueDateLabel'), value: 'end', width: 120, sortable: false, divider: true },
      { text: this.$t('pointsLabel'), value: 'points', width: 120, sortable: false, divider: true },
      { text: this.$t('notesLabel'), value: 'noteText', width: 400, sortable: false, divider: true }
    ];

    studentAssignmentsTable: Array<any> = [];
    filteredStudentAssignmentsTable: Array<any> = [];
    studentAssessmentsTable: Array<any> = [];
    filteredStudentAssessmentsTable: Array<any> = [];

    loadSingleStudent(studentId: any) {
      const payload = { studentId: studentId, subjectId: this.selectedClass.value, gradePeriodId: this.selectedPeriod };
      this.getScoresForStudent(payload).then((d: any) => {
        this.currentStudentData = d.data;
        if (!this.currentStudentData.assessments && !this.currentStudentData.assignments) {
          this.exitSingleStudent();
          return;
        }
        this.hasGoogleGrades = false;
        const additionalInfo = this.allStudents.find((s: any) => s.studentId === this.currentStudentData.studentId);
        if (additionalInfo) {
          this.currentStudentData.photoUrl = additionalInfo.photoUrl || '';
          this.currentStudentData.initials = this.getInitials(additionalInfo);
        }
        this.currentStudentData.percentageCopy = this.currentStudentData.percentage;
        this.studentAssessmentsTable = [];
        this.currentStudentData.assessments.map((a: any) => {
          if (a.score.code) {
            a.score.points = a.score.code;
          }
          a.score.pointsCopy = a.score.points;
          if (!a.noteText) {
            a.noteText = '';
          }
          a.noteTextCopy = a.noteText;
          if (a.source === 'gc') {
            this.hasGoogleGrades = true;
            a.scoreWeightItem = this.assessmentCategories.find((ac: any) => ac.scoreWeightId === a.scoreWeightId)
          }
          this.studentAssessmentsTable.push(a);

          for (const s in a.standards) {
            const standard = a.standards[s]
            standard.scoreWeightName = 'Standard'
            standard.end = a.end;
            standard.code = standard.score.code;
            standard.scoreId = standard.score.scoreId;
            standard.codeCopy = standard.score.code;
            standard.assignmentId = 0;
            standard.assessmentId = a.id;
            standard.type = 'm';
            this.studentAssessmentsTable.push(standard);
          }
        })

        this.studentAssignmentsTable = [];
        this.currentStudentData.assignments.map((a: any) => {
          if (a.score.code) {
            a.score.points = a.score.code;
          }
          a.score.pointsCopy = a.score.points;
          if (!a.noteText) {
            a.noteText = '';
          }
          a.noteTextCopy = a.noteText;
          if (a.source === 'gc') {
            this.hasGoogleGrades = true;
            a.scoreWeightItem = this.assignmentCategories.find((ac: any) => ac.scoreWeightId === a.scoreWeightId)
          }

          this.studentAssignmentsTable.push(a);
          for (const s in a.standards) {
            const standard = a.standards[s]
            standard.scoreWeightName = 'Standard'
            standard.end = a.end;
            standard.code = standard.score.code;
            standard.scoreId = standard.score.scoreId
            standard.codeCopy = standard.score.code;
            standard.assignmentId = a.id;
            standard.assessmentId = 0;
            standard.type = 'm';
            this.studentAssignmentsTable.push(standard);
          }
        })

        this.filteredStudentAssessmentsTable = this.studentAssessmentsTable;
        this.filteredStudentAssignmentsTable = this.studentAssignmentsTable;
        CommonUtils.hideLoading();
      });
    }

    filterTablesStandards() {
      if (this.showStandards) {
        this.filteredStudentAssignmentsTable = this.studentAssignmentsTable;
        this.filteredStudentAssessmentsTable = this.studentAssessmentsTable;
      } else {
        this.filteredStudentAssignmentsTable = this.studentAssignmentsTable.filter((a: any) => a.scoreWeightName !== 'Standard');
        this.filteredStudentAssessmentsTable = this.studentAssessmentsTable.filter((a: any) => a.scoreWeightName !== 'Standard');
      }
    }

    editStudentGrades(rowData: any) {
      CommonUtils.showLoading();
      this.singleStudent = true;
      this.loadSingleStudent(rowData.studentId);
    }

    priorStudent() {
      CommonUtils.showLoading();
      this.loadSingleStudent(this.currentStudentData.previousStudentId);
    }

    nextStudent() {
      CommonUtils.showLoading();
      this.loadSingleStudent(this.currentStudentData.nextStudentId);
    }

    exitSingleStudent() {
      this.singleStudent = false;
      this.refreshTable();
    }

    // UPDATES SINGLE-STUDENT VIEW
    saveOverridePercentageSM() {
      if (!this.selectedPeriod || this.selectedPeriod === 0) {
        this.overridePercentageWrapper(this.currentStudentData);
      } else {
        this.overridePeriodWrapper(this.currentStudentData, this.currentStudentData, this.selectedPeriod);
      }
    }

    saveNote(classwork: any, isAssignment: boolean) {
      if (classwork.noteTextCopy === classwork.noteText) {
        return;
      }
      Vue.set(classwork, 'noteStatus', 'PENDING');
      const payload: any = {
        id: classwork.noteId || 0,
        typeId: classwork.id,
        parentId: this.currentStudentData.studentId,
        text: classwork.noteText
      }
      if (classwork.scoreWeightName === 'Standard') {
        payload.type = 'SG';
      } else {
        payload.type = isAssignment ? 'G' : 'M';
        payload.subjectId = this.currentStudentData.subjectId;
      }
      if (this.currentStudentData.source === 'gc') {
        payload.parentId = this.currentStudentData.subjectId;
        payload.sourceId = classwork.sourceId
        payload.sourceStudentId = this.currentStudentData.sourceId;
      }

      this.addNote(payload).then((d) => {
        Vue.set(classwork, 'noteStatus', 'SUCCESS');
        classwork.noteTextCopy = classwork.noteText;
        classwork.noteId = d.data.id
      }).catch(() => {
        Vue.set(classwork, 'noteStatus', 'FAILED');
      })
    }

    saveGradeSingleStudent(classwork: any, type: string) {
      if (classwork.score.pointsCopy === classwork.score.points) {
        return;
      }
      Vue.set(classwork.score, 'status', 'PENDING');
      const payload: any = {
        gradePeriodId: this.selectedPeriod,
        subjectId: this.currentStudentData.subjectId,
        scoreId: classwork.score.scoreId,
        studentId: this.currentStudentData.studentId,
        totalPoints: classwork.score.points,
        type: type
      }
      if (type === 'm') {
        payload.assessmentId = classwork.id;
        payload.assignmentId = 0;
      } else {
        payload.assessmentId = 0;
        payload.assignmentId = classwork.id;
      }
      if (classwork.source === 'gc') {
        payload.sourceId = classwork.sourceId;
      }
      if (this.currentStudentData.source === 'gc') {
        payload.sourceStudentId = this.currentStudentData.sourceId;
      }

      this.updateStudentScore(payload).then((d: any) => {
        Vue.set(classwork.score, 'status', 'SUCCESS');
        classwork.score.points = d.data.studentScore.totalPoints;
        if (classwork.score.points === -1) {
          classwork.score.points = '';
        } else if (d.data.studentScore.code) {
          classwork.score.points = d.data.studentScore.code
        }
        classwork.score.pointsCopy = classwork.score.points;
        classwork.score.scoreId = d.data.studentScore.scoreId;
        this.currentStudentData.percentage = d.data.percentage;
        this.currentStudentData.letterGrade = d.data.letterGrade;

        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg44') as string);
        }

        return Promise.resolve();
      }).catch(() => {
        Vue.set(classwork.score, 'status', 'FAILED');
      });
    }
}
