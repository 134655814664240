
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { exportTypes } from '@/constants';
import CommonUtils from '@/utils/common-utils';

const classes = namespace('classes');
const settings = namespace('settings');
const grades = namespace('grades');
const students = namespace('students');

@Component
export default class PrintGrades extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  @Prop()
  classList!: any;

  @Prop()
  selectedClass!: any;

  @Prop({ required: false, default: '' })
  selectedPeriod!: string;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter
  getSettings!: any;

  @classes.Getter
  getClassesMap!: any;

  @grades.Action
  printGradeReports!: (params?: any) => Promise<any>;

  @students.Getter
  getStudentAccountGradesData!: any;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('update:value', val);
  }

  get exportTypes() {
    if (this.userMode === 'A') {
      return exportTypes;
    } else {
      return [{ text: this.$t('pdfLabel'), value: 'PDF' },
        { text: this.$t('csvLabel'), value: 'CSV' }]
    }
  }

  get localClassList() {
    if (this.userMode === 'T') {
      return this.classList;
    } else {
      const associatedSubjects = [{ text: this.$t('allSubjectsLabel'), value: 0 }];
      if (this.exportTeacher && this.exportTeacher.teacherId > 0) {
        for (const i in this.exportTeacher.subjects) {
          const subject = this.exportTeacher.subjects[i];
          subject.text = subject.info.name;
          subject.value = subject.info.subjectId;
          associatedSubjects.push(subject)
        }
      }
      return associatedSubjects;
    }
  }

  get periodList() {
    if (this.userMode === 'T') {
      return [{ name: this.$t('allPeriodsLabel'), gradePeriodId: 0 }].concat(this.getSettings.accountSettings.gradingPeriods);
    } else {
      let associatedPeriods = [];
      if (this.exportTeacher && this.exportTeacher.teacherId > 0) {
        for (const i in this.exportTeacher.subjects) {
          associatedPeriods = this.exportTeacher.subjects[i].periods.map((p: any) => { return p.gradePeriod });
          break;
        }
      }
      return [{ name: this.$t('allPeriodsLabel'), gradePeriodId: 0 }].concat(associatedPeriods);
    }
  }

  exportType: any = null;
  exportPeriod: any = null;
  exportTeacher: any = null;
  exportClass: any = null;
  exportItemsSelected: any = [];
  teacherList: Array<any> = [];
  exportItems = [
    { title: this.$t('attendanceLabel'), field: 'gradeReportItems', code: 4 },
    { title: this.$t('assignmentsLabel'), field: 'gradeReportItems', code: 1 },
    { title: this.$t('assessmentsLabel'), field: 'gradeReportItems', code: 2 },
    { title: this.$t('standardsLabel'), field: 'gradeReportItems', code: 3 }
  ]

  @Watch('value')
  init() {
    if (this.value) {
      this.openPrintOptions();
    }
  }

  openPrintOptions() {
    this.exportType = this.exportTypes[0];
    this.exportItemsSelected = this.exportItems;
    if (this.userMode === 'T') {
      this.exportClass = this.selectedClass;
      this.exportPeriod = this.selectedPeriod !== '' ? this.selectedPeriod : this.periodList[0];
    } else if (this.userMode === 'S') {
      this.teacherList = [{ displayName: this.$t('allTeachersLabel'), teacherId: 0 }].concat(this.getStudentAccountGradesData.teachers);
      this.exportTeacher = this.teacherList[0];
      this.exportPeriod = this.selectedPeriod !== '' ? this.selectedPeriod : this.periodList[0];
    }
  }

  doRequestPrint() {
    this.localValue = false;
    const payload: any = {
      exportType: CommonUtils.get(this.exportType.value, this.exportType),
      period: this.exportPeriod.gradePeriodId,
      class: this.exportClass.value,
      items: this.exportItemsSelected
    }
    if (this.userMode === 'S') {
      payload.teacher = this.exportTeacher.teacherId
    }
    this.printGradeReports(payload)
  }

  @Watch('exportTeacher')
  updateTeacher() {
    this.exportClass = this.localClassList[0];
    this.exportPeriod = this.periodList[0];
  }
}
