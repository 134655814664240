var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: {
        maxWidth: 600,
        expandable: false,
        withActions: false,
        withApply: false
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("performanceReportsLabel")))]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "pb-btn",
                {
                  staticClass: "mr-1",
                  attrs: { label: _vm.$t("sendLabel"), icon: "", right: "" },
                  on: { click: _vm.printReports }
                },
                [_c("v-icon", [_vm._v("fal fa-arrow-up-from-square")])],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-container",
        { staticClass: "pb-7" },
        [
          _c(
            "v-form",
            { ref: "performanceReportForm", attrs: { "lazy-validation": "" } },
            [
              _vm.userMode == "A"
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("pb-date-field", {
                            attrs: {
                              value: _vm.date,
                              hideDetails: "auto",
                              dense: "",
                              autofocus: ""
                            },
                            on: {
                              "update:value": function($event) {
                                _vm.date = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.userMode == "A"
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("gradeLevelLabel"),
                              dense: "",
                              "display:inline-flex": "",
                              "return-object": "",
                              "hide-details": "auto",
                              items: _vm.gradesList,
                              "item-text": "text",
                              outlined: ""
                            },
                            model: {
                              value: _vm.levelFilter,
                              callback: function($$v) {
                                _vm.levelFilter = $$v
                              },
                              expression: "levelFilter"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.userMode != "S"
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("studentLabel"),
                              dense: "",
                              "display:inline-flex": "",
                              "return-object": "",
                              "hide-details": "auto",
                              items: _vm.studentOptions,
                              "item-text": "displayName",
                              rules: _vm.localRules,
                              outlined: ""
                            },
                            model: {
                              value: _vm.selectedStudent,
                              callback: function($$v) {
                                _vm.selectedStudent = $$v
                              },
                              expression: "selectedStudent"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.userMode != "T" && _vm.additionalAdminOptions
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("teacherLabel"),
                              dense: "",
                              "display:inline-flex": "",
                              "return-object": "",
                              "hide-details": "auto",
                              items: _vm.teacherList,
                              "item-text": "displayName",
                              outlined: ""
                            },
                            model: {
                              value: _vm.selectedTeacher,
                              callback: function($$v) {
                                _vm.selectedTeacher = $$v
                              },
                              expression: "selectedTeacher"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.additionalAdminOptions
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("gradePeriodLabel"),
                              dense: "",
                              "display:inline-flex": "",
                              "return-object": "",
                              "hide-details": "auto",
                              items: _vm.periodOptions,
                              "item-text": "name",
                              outlined: ""
                            },
                            model: {
                              value: _vm.exportPeriod,
                              callback: function($$v) {
                                _vm.exportPeriod = $$v
                              },
                              expression: "exportPeriod"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.userMode != "T" && _vm.additionalAdminOptions
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("subjectLabel"),
                              dense: "",
                              "display:inline-flex": "",
                              "return-object": "",
                              "hide-details": "auto",
                              items: _vm.subjectList,
                              "item-text": "className",
                              outlined: ""
                            },
                            model: {
                              value: _vm.selectedSubject,
                              callback: function($$v) {
                                _vm.selectedSubject = $$v
                              },
                              expression: "selectedSubject"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.userMode != "T"
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              "hide-details": "auto",
                              dense: "",
                              label: _vm.$t("includeClassworkGrades")
                            },
                            model: {
                              value: _vm.includeNotes,
                              callback: function($$v) {
                                _vm.includeNotes = $$v
                              },
                              expression: "includeNotes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        staticClass: "mt-1",
                        attrs: {
                          label: _vm.$t("typeLabel"),
                          dense: "",
                          "display:inline-flex": "",
                          "return-object": "",
                          "hide-details": "auto",
                          items: _vm.typeList,
                          "item-text": "title",
                          outlined: "",
                          multiple: ""
                        },
                        on: {
                          keyup: [
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "right",
                                  39,
                                  $event.key,
                                  ["Right", "ArrowRight"]
                                )
                              ) {
                                return null
                              }
                              if ("button" in $event && $event.button !== 2) {
                                return null
                              }
                              return _vm.setAttendanceBySubject(true)
                            },
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "left", 37, $event.key, [
                                  "Left",
                                  "ArrowLeft"
                                ])
                              ) {
                                return null
                              }
                              if ("button" in $event && $event.button !== 0) {
                                return null
                              }
                              return _vm.setAttendanceBySubject(false)
                            }
                          ]
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-item",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: { ripple: "" },
                                    on: {
                                      mousedown: function($event) {
                                        $event.preventDefault()
                                      },
                                      click: _vm.selectAllTypes
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            "input-value":
                                              _vm.selectedTypes.length ===
                                              _vm.typeList.length
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            _vm._s(_vm.$t("allTypesLabel"))
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              var index = ref.index
                              return [
                                index == 0
                                  ? _c("span", [
                                      _vm.selectedTypes.length == 0
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("selectTypesLabel"))
                                            )
                                          ])
                                        : _vm.selectedTypes.length == 1
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.title))
                                          ])
                                        : _vm.selectedTypes.length == 2
                                        ? _c("span", [
                                            _vm._v(
                                              "2 " +
                                                _vm._s(_vm.$t("typesLabel"))
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("allTypesLabel"))
                                            )
                                          ])
                                    ])
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(ref) {
                              var active = ref.active
                              var item = ref.item
                              var attrs = ref.attrs
                              var on = ref.on
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        ref: "item-" + item.title,
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var active = ref.active
                                                return [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          "input-value": active
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters":
                                                                  "",
                                                                align: "center"
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                )
                                                              ]),
                                                              item.title ==
                                                                _vm.$t(
                                                                  "attendanceLabel"
                                                                ) && active
                                                                ? _c(
                                                                    "v-switch",
                                                                    {
                                                                      staticClass:
                                                                        "compact",
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "perSubjectLabel"
                                                                        ),
                                                                        dense:
                                                                          "",
                                                                        inset:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.attendanceBySubject,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.attendanceBySubject = $$v
                                                                        },
                                                                        expression:
                                                                          "attendanceBySubject"
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selectedTypes,
                          callback: function($$v) {
                            _vm.selectedTypes = $$v
                          },
                          expression: "selectedTypes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.userMode == "T"
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("pb-multi-select", {
                            attrs: {
                              label: _vm.$t("itemsLabel"),
                              items: _vm.itemList,
                              itemText: "title",
                              selected: _vm.selectedItems
                            },
                            on: {
                              "update:selected": function($event) {
                                _vm.selectedItems = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }