var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$vuetify.breakpoint.smAndDown && !_vm.singleStudent
    ? _c(
        "v-sheet",
        [
          _vm.isLoading
            ? _c("v-card")
            : _vm.error != ""
            ? _c(
                "v-card",
                { staticClass: "mt-10 text-center", attrs: { flat: "" } },
                [_vm._v(_vm._s(_vm.error))]
              )
            : _vm.viewItem === null && !_vm.singleStudent
            ? _c("v-data-table", {
                attrs: {
                  "hide-default-header": "",
                  "hide-default-footer": "",
                  "items-per-page": -1,
                  headers: _vm.mobileHeaders,
                  items: _vm.filteredMobileItems,
                  "mobile-breakpoint": 0,
                  height: "calc(100vh - 172px)"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.date",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("td", [
                            item.value === "OA"
                              ? _c("div")
                              : item.value === "GP"
                              ? _c(
                                  "div",
                                  [
                                    _c("v-icon", [
                                      _vm._v("fal fa-calendar-range")
                                    ])
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.formatDateYearless(item.dueDate))
                                  )
                                ])
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.title",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "td",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          item.source === "gc"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { text: "", small: "" }
                                                },
                                                [_vm._v("fa-brands fa-google")]
                                              )
                                            : _vm._e(),
                                          _vm._v(_vm._s(item.text))
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("v-row", [_vm._v(_vm._s(item.group))])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.average",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          ["OA", "GP"].includes(item.value)
                            ? _c(
                                "td",
                                [
                                  item.average !== "NaN"
                                    ? _c(
                                        "v-col",
                                        [
                                          _c("v-row", [
                                            _vm._v(
                                              _vm._s(item.average) +
                                                " " +
                                                _vm._s(
                                                  _vm.getLetterGrade(
                                                    item.average
                                                  )
                                                )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c(
                                "td",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      item.value !== "S" &&
                                      item.average === "NaN"
                                        ? _c("v-row", [
                                            _vm._v(
                                              "0 / " + _vm._s(item.totalPoints)
                                            )
                                          ])
                                        : item.value !== "S"
                                        ? _c("v-row", [
                                            _vm._v(
                                              _vm._s(item.average) +
                                                " / " +
                                                _vm._s(item.totalPoints)
                                            )
                                          ])
                                        : _vm._e(),
                                      _c("v-row", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("percentReportedLabel", {
                                              val: item.reporting
                                            })
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ]
                      }
                    },
                    {
                      key: "item.action",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "td",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    text: "",
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.viewItem = item
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("editLabel")))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm.viewItem
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-4 mt-4 mb-4 ml-0 pl-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mx-0 px-0 pt-0 pb-0 pl-3",
                          attrs: { cols: "2" }
                        },
                        [
                          _c(
                            "pb-btn",
                            {
                              staticClass: "mt-0 pt-0 ml-0 pl-0",
                              attrs: {
                                label: _vm.$t("closeLabel"),
                                small: "",
                                text: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.viewItem = null
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("fal fa-xmark")])],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _vm.viewItem.value === "GP"
                            ? _c(
                                "v-row",
                                {
                                  staticClass:
                                    "font-weight-bold text-decoration-underline",
                                  attrs: { tabindex: "0", role: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "showPeriod",
                                        _vm.viewItem
                                      )
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.$emit(
                                        "showPeriod",
                                        _vm.viewItem
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.viewItem.text))]
                              )
                            : _vm.viewItem.value === "S"
                            ? _c(
                                "v-row",
                                {
                                  staticClass:
                                    "font-weight-bold text-decoration-underline",
                                  attrs: { tabindex: "0", role: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editClasswork(
                                        _vm.viewItem.type,
                                        _vm.viewItem.classwork,
                                        _vm.viewItem.classwork.source
                                      )
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.editClasswork(
                                        _vm.viewItem.type,
                                        _vm.viewItem.classwork,
                                        _vm.viewItem.classwork.source
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.viewItem.text))]
                              )
                            : _vm.viewItem.type === "g" ||
                              _vm.viewItem.type === "m"
                            ? _c(
                                "v-row",
                                {
                                  staticClass:
                                    "font-weight-bold text-decoration-underline",
                                  attrs: { tabindex: "0", role: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editClasswork(
                                        _vm.viewItem.type,
                                        _vm.viewItem,
                                        _vm.viewItem.source
                                      )
                                    },
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.editClasswork(
                                        _vm.viewItem.type,
                                        _vm.viewItem,
                                        _vm.viewItem.source
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.viewItem.text))]
                              )
                            : _c("v-row", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.viewItem.text))
                              ]),
                          _c("v-row", [_vm._v(_vm._s(_vm.viewItem.group))])
                        ],
                        1
                      ),
                      ["OA", "GP"].includes(_vm.viewItem.value)
                        ? _c(
                            "div",
                            [
                              _vm.viewItem.average !== "NaN"
                                ? _c(
                                    "v-col",
                                    [
                                      _c("v-row", [
                                        _vm._v(
                                          _vm._s(_vm.$t("classAverageLabel")) +
                                            ": " +
                                            _vm._s(_vm.viewItem.average) +
                                            " " +
                                            _vm._s(
                                              _vm.getLetterGrade(
                                                _vm.viewItem.average
                                              )
                                            )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.viewItem.value !== "S" &&
                                  _vm.viewItem.average === "NaN"
                                    ? _c("v-row", [
                                        _vm._v(
                                          _vm._s(_vm.$t("classAverageLabel")) +
                                            ": 0 / " +
                                            _vm._s(_vm.viewItem.totalPoints)
                                        )
                                      ])
                                    : _vm.viewItem.value !== "S"
                                    ? _c("v-row", [
                                        _vm._v(
                                          _vm._s(_vm.$t("classAverageLabel")) +
                                            ": " +
                                            _vm._s(_vm.viewItem.average) +
                                            " / " +
                                            _vm._s(_vm.viewItem.totalPoints)
                                        )
                                      ])
                                    : _vm._e(),
                                  _c("v-row", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("percentReportedLabel", {
                                          val: _vm.viewItem.reporting
                                        })
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-data-table", {
                    attrs: {
                      "hide-default-footer": "",
                      "hide-default-header": "",
                      headers: _vm.mobileHeadersItem,
                      "items-per-page": -1,
                      "mobile-breakpoint": 0,
                      items: _vm.sortedStudentData,
                      height: "calc(100vh - 172px)"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.student",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                {
                                  attrs: { tabindex: "0", role: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editStudentGrades(item)
                                    },
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.editStudentGrades(item)
                                    }
                                  }
                                },
                                [
                                  item.source == "gc"
                                    ? _c("v-icon", {
                                        staticClass: "fa-brands fa-google mx-3",
                                        attrs: { text: "", small: "" }
                                      })
                                    : _c(
                                        "v-avatar",
                                        {
                                          staticClass: "ml-1 mb-1 mr-1",
                                          attrs: {
                                            color: "primary",
                                            size: "2.0rem"
                                          }
                                        },
                                        [
                                          item.photoUrl && item.photoUrl !== ""
                                            ? _c("v-img", {
                                                attrs: { src: item.photoUrl }
                                              })
                                            : _c(
                                                "span",
                                                { staticClass: "white--text" },
                                                [_vm._v(_vm._s(item.initials))]
                                              )
                                        ],
                                        1
                                      ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-decoration-underline"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.truncateName(item.studentName)
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "item.letterGrade",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                _vm.viewItem.value === "OA"
                                  ? _c("div", [
                                      _vm._v(_vm._s(item.letterGrade))
                                    ])
                                  : _vm.viewItem.value === "GP"
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          item.periods[_vm.viewItem.id]
                                            .letterGrade
                                        )
                                      )
                                    ])
                                  : _vm.viewItem.value !== "S"
                                  ? _c("div")
                                  : _vm._e()
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.grade",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                [
                                  _vm.viewItem.value === "OA"
                                    ? _c("v-text-field", {
                                        staticClass: "centerField mr-3",
                                        class: _vm.getStatusClass(
                                          item.percentageStatus
                                        ),
                                        staticStyle: {
                                          width: "70px",
                                          display: "inline-block"
                                        },
                                        attrs: {
                                          dense: "",
                                          flat: "",
                                          solo: "",
                                          outlined: "",
                                          "single-line": "",
                                          "hide-details": "",
                                          "append-icon": _vm.getAppendIcon(
                                            item.percentageStatus
                                          ),
                                          "aria-label": _vm.$t(
                                            "overallGradeFor",
                                            { student: item.studentName }
                                          )
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.overridePercentageWrapper(
                                              item
                                            )
                                          }
                                        },
                                        model: {
                                          value: item.percentage,
                                          callback: function($$v) {
                                            _vm.$set(item, "percentage", $$v)
                                          },
                                          expression: "item.percentage"
                                        }
                                      })
                                    : _vm.viewItem.value === "GP"
                                    ? _c("v-text-field", {
                                        staticClass: "centerField mr-3",
                                        class: _vm.getStatusClass(
                                          item.periods[_vm.viewItem.id]
                                            .percentageStatus
                                        ),
                                        staticStyle: {
                                          width: "70px",
                                          display: "inline-block"
                                        },
                                        attrs: {
                                          dense: "",
                                          flat: "",
                                          solo: "",
                                          outlined: "",
                                          "single-line": "",
                                          "hide-details": "",
                                          "append-icon": _vm.getAppendIcon(
                                            item.periods[_vm.viewItem.id]
                                              .percentageStatus
                                          ),
                                          "aria-label": _vm.$t(
                                            "periodGradeFor",
                                            {
                                              period: _vm.viewItem.text,
                                              student: item.studentName
                                            }
                                          )
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.overridePeriodWrapper(
                                              item,
                                              item.periods[
                                                _vm.viewItem.gradePeriodId
                                              ],
                                              _vm.viewItem.gradePeriodId
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            item.periods[_vm.viewItem.id]
                                              .percentage,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item.periods[_vm.viewItem.id],
                                              "percentage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.periods[viewItem.id].percentage"
                                        }
                                      })
                                    : _vm.viewItem.value !== "S"
                                    ? _c("v-text-field", {
                                        staticClass: "centerField mr-3",
                                        class: _vm.getStatusClass(
                                          item[_vm.viewItem.id].status
                                        ),
                                        staticStyle: {
                                          width: "70px",
                                          display: "inline-block"
                                        },
                                        attrs: {
                                          dense: "",
                                          flat: "",
                                          solo: "",
                                          outlined: "",
                                          "single-line": "",
                                          "hide-details": "",
                                          "append-icon": _vm.getAppendIcon(
                                            item[_vm.viewItem.id].status
                                          ),
                                          "aria-label": _vm.$t(
                                            "classworkScoreFor",
                                            {
                                              classwork: _vm.viewItem.text,
                                              student: item.studentName
                                            }
                                          )
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.saveGrade(
                                              item,
                                              _vm.viewItem
                                            )
                                          }
                                        },
                                        model: {
                                          value: item[_vm.viewItem.value].score,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item[_vm.viewItem.value],
                                              "score",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item[viewItem.value].score"
                                        }
                                      })
                                    : _c("v-text-field", {
                                        staticClass: "centerField mr-3",
                                        class: _vm.getStatusClass(
                                          item[_vm.viewItem.classworkId]
                                            .standards[_vm.viewItem.dbId].status
                                        ),
                                        staticStyle: {
                                          width: "70px",
                                          display: "inline-block"
                                        },
                                        attrs: {
                                          dense: "",
                                          flat: "",
                                          solo: "",
                                          outlined: "",
                                          "single-line": "",
                                          "hide-details": "",
                                          "append-icon": _vm.getAppendIcon(
                                            item[_vm.viewItem.classworkId]
                                              .standards[_vm.viewItem.dbId]
                                              .status
                                          ),
                                          "aria-label": _vm.$t(
                                            "classworkScoreFor",
                                            {
                                              classwork: _vm.viewItem.text,
                                              student: item.studentName
                                            }
                                          )
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.saveStandardWrapper(
                                              item[_vm.viewItem.classworkId]
                                                .standards[_vm.viewItem.dbId],
                                              item,
                                              _vm.viewItem.type
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            item[_vm.viewItem.classworkId]
                                              .standards[_vm.viewItem.dbId]
                                              .code,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item[_vm.viewItem.classworkId]
                                                .standards[_vm.viewItem.dbId],
                                              "code",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item[viewItem.classworkId].standards[viewItem.dbId].code"
                                        }
                                      })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _c(
        "v-sheet",
        [
          _vm.isLoading
            ? _c("v-card")
            : _vm.error != ""
            ? _c(
                "v-card",
                { staticClass: "mt-10 text-center", attrs: { flat: "" } },
                [_vm._v(_vm._s(_vm.error))]
              )
            : !_vm.singleStudent
            ? _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "layout-container fill-height fill-width",
                      attrs: { fluid: "" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-nowrap fill-height" },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "d-flex flex-nowrap",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "perfect-scrollbar",
                                    { style: { height: "240px" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "first-column-header pt-4 pl-8"
                                        },
                                        [
                                          _vm.hasGoogleGrades
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pb-0 pl-0 pr-0 pt-0"
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        dense: ""
                                                      },
                                                      on: {
                                                        click: _vm.refreshTable
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "refreshGoogle"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-1 pl-0 pr-0 pt-1"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "showAssignmentsLabel"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-0 pl-0 pr-0 pt-0"
                                            },
                                            [
                                              _c(
                                                "v-btn-toggle",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    dense: "",
                                                    mandatory: ""
                                                  },
                                                  model: {
                                                    value: _vm.showAssignments,
                                                    callback: function($$v) {
                                                      _vm.showAssignments = $$v
                                                    },
                                                    expression:
                                                      "showAssignments"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.areAssignments
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.allAssignments
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("allLabel")
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      on: {
                                                        click:
                                                          _vm.hideAssignments
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("noneLabel")
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.areAssignments
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.traverseAssignment(
                                                            -1
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("priorLabel")
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.areAssignments
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.traverseAssignment(
                                                            1
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("nextLabel")
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-1 pl-0 pr-0 pt-1"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "showAssessmentsLabel"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-1 pl-0 pr-0 pt-0"
                                            },
                                            [
                                              _c(
                                                "v-btn-toggle",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    dense: "",
                                                    mandatory: ""
                                                  },
                                                  model: {
                                                    value: _vm.showAssessments,
                                                    callback: function($$v) {
                                                      _vm.showAssessments = $$v
                                                    },
                                                    expression:
                                                      "showAssessments"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.areAssessments
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.allAssessments
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("allLabel")
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      on: {
                                                        click:
                                                          _vm.hideAssessments
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("noneLabel")
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.areAssessments
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.traverseAssessment(
                                                            -1
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("priorLabel")
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.areAssessments
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.traverseAssessment(
                                                            1
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("nextLabel")
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-1 pl-0 pr-0 pt-1"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("scoreKeyLabel")
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-0 pl-3 pr-0 pt-0"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("excusedKeyLabel")
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-0 pl-3 pr-0 pt-0"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("incompleteKeyLabel")
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-0 pl-3 pr-0 pt-0"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("missingKeyLabel")
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "d-flex flex-nowrap first-column-cell",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c("div", { staticClass: "ml-3" }, [
                                    _vm._v(_vm._s(_vm.$t("dueDateLabel")))
                                  ]),
                                  _c("v-spacer"),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(_vm._s(_vm.$t("sortLabel")))
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-2 mt-1",
                                      attrs: {
                                        elevation: "0",
                                        small: "",
                                        color: "primary",
                                        "aria-label": _vm.$t("reverseDateLabel")
                                      },
                                      on: { click: _vm.reverseDateOrder }
                                    },
                                    [
                                      _vm.ascendingDateOrder
                                        ? _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("fa fa-caret-left")]
                                          )
                                        : _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("fa fa-caret-right")]
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "evenrow first-column-cell",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c("div", { staticClass: "ml-3" }, [
                                    _vm._v(_vm._s(_vm.$t("classAverageLabel")))
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  ref: "scrollStudents",
                                  staticClass: "pb-1 hidden-scrollbar",
                                  staticStyle: {
                                    "overflow-y": "auto",
                                    "max-height": "calc(100vh - 430px)"
                                  },
                                  on: {
                                    scroll: function($event) {
                                      return _vm.scrollSync(0)
                                    }
                                  }
                                },
                                _vm._l(_vm.sortedStudentData, function(
                                  student,
                                  index
                                ) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: student.studentId,
                                      class: { evenrow: index % 2 == 1 },
                                      attrs: { "no-gutters": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "first-column-cell clickable",
                                          attrs: {
                                            tabindex: "0",
                                            role: "button"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.editStudentGrades(
                                                student
                                              )
                                            },
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.editStudentGrades(
                                                student
                                              )
                                            }
                                          }
                                        },
                                        [
                                          student.source == "gc"
                                            ? _c("v-icon", {
                                                staticClass:
                                                  "fa-brands fa-google mx-3",
                                                attrs: { text: "", small: "" }
                                              })
                                            : _c(
                                                "v-avatar",
                                                {
                                                  staticClass: "ml-1 mb-1 mr-1",
                                                  attrs: {
                                                    color: "primary",
                                                    size: "2.0rem"
                                                  }
                                                },
                                                [
                                                  student.photoUrl &&
                                                  student.photoUrl !== ""
                                                    ? _c("v-img", {
                                                        attrs: {
                                                          src: student.photoUrl
                                                        }
                                                      })
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "white--text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              student.initials
                                                            )
                                                          )
                                                        ]
                                                      )
                                                ],
                                                1
                                              ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.truncateName(
                                                  student.studentName
                                                )
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c("div", [
                            _c(
                              "div",
                              {
                                ref: "scrollHeaders",
                                staticClass: "pb-0 hidden-scrollbar",
                                staticStyle: {
                                  "overflow-x": "auto",
                                  "max-width": "calc(100vw - 430px)"
                                },
                                on: {
                                  scroll: function($event) {
                                    return _vm.scrollSyncHorizontal(0)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "d-flex flex-nowrap",
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _vm.fullYearMode
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "period-header-cell rotate d-flex flex-nowrap"
                                          },
                                          [
                                            _c("div", { staticClass: "mt-2" }, [
                                              _vm._v(
                                                _vm._s(_vm.overallHeader.text)
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._l(_vm.periodHeaders, function(
                                      header,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: header.id },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "div",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                ref:
                                                                  "headerP" +
                                                                  index,
                                                                refInFor: true,
                                                                staticClass:
                                                                  "clickable d-flex flex-nowrap",
                                                                attrs: {
                                                                  tabindex: "0",
                                                                  role: "button"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$emit(
                                                                      "showPeriod",
                                                                      header
                                                                    )
                                                                  },
                                                                  keydown: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    return _vm.$emit(
                                                                      "showPeriod",
                                                                      header
                                                                    )
                                                                  },
                                                                  focus: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.itemFocused =
                                                                      header.id
                                                                  },
                                                                  blur: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.itemFocused =
                                                                      ""
                                                                  }
                                                                }
                                                              },
                                                              "div",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "period-header-cell rotate mt-0",
                                                                class: {
                                                                  "header-focused":
                                                                    _vm.itemFocused ===
                                                                    header.id
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2"
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.truncateTitle(
                                                                            header.text
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.truncateTitle(
                                                      header.text
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    _vm._l(
                                      _vm.filteredClassworkHeaders,
                                      function(classwork, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: classwork.id,
                                            staticClass: "d-flex flex-nowrap"
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  ref:
                                                                    "headerC" +
                                                                    index,
                                                                  refInFor: true,
                                                                  staticClass:
                                                                    "header-cell rotate",
                                                                  class: {
                                                                    "header-focused":
                                                                      _vm.itemFocused ===
                                                                      classwork.id
                                                                  },
                                                                  attrs: {
                                                                    tabindex:
                                                                      "0",
                                                                    role:
                                                                      "button"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.editClasswork(
                                                                        classwork.type,
                                                                        classwork,
                                                                        classwork.source
                                                                      )
                                                                    },
                                                                    keydown: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter",
                                                                          13,
                                                                          $event.key,
                                                                          "Enter"
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      return _vm.editClasswork(
                                                                        classwork.type,
                                                                        classwork,
                                                                        classwork.source
                                                                      )
                                                                    },
                                                                    focus: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.itemFocused =
                                                                        classwork.id
                                                                    },
                                                                    blur: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.itemFocused =
                                                                        ""
                                                                    }
                                                                  }
                                                                },
                                                                "div",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              classwork.source ==
                                                              "gc"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "mx-6 mt-2"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "fa-brands fa-google",
                                                                          staticStyle: {
                                                                            "padding-top":
                                                                              "5px",
                                                                            transform:
                                                                              "rotate(90deg)"
                                                                          },
                                                                          attrs: {
                                                                            color:
                                                                              "white",
                                                                            text:
                                                                              "",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "clickable"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.truncateTitle(
                                                                                classwork.text
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c("br"),
                                                                      _c(
                                                                        "v-menu",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              "",
                                                                            right:
                                                                              "",
                                                                            "close-on-click":
                                                                              "",
                                                                            absolute:
                                                                              "",
                                                                            "close-on-content-click": false
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "span",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            staticClass:
                                                                                              "clickable",
                                                                                            style: {
                                                                                              "font-size":
                                                                                                _vm.categoryFontSize
                                                                                            }
                                                                                          },
                                                                                          "span",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.truncateTitle(
                                                                                              classwork.group
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle: {
                                                                                "background-color":
                                                                                  "white"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-4 mb-4"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "selectScoreWeight"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mt-1",
                                                                                      attrs: {
                                                                                        dense:
                                                                                          "",
                                                                                        "display:inline-flex":
                                                                                          "",
                                                                                        "return-object":
                                                                                          "",
                                                                                        "hide-details":
                                                                                          "auto",
                                                                                        items:
                                                                                          classwork.type ==
                                                                                          "g"
                                                                                            ? _vm.assignmentCategories
                                                                                            : _vm.assessmentCategories,
                                                                                        "item-text":
                                                                                          "name",
                                                                                        outlined:
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        change: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.changeCategoryGoogle(
                                                                                            classwork,
                                                                                            classwork.scoreWeightItem
                                                                                          )
                                                                                        }
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          classwork.scoreWeightItem,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            classwork,
                                                                                            "scoreWeightItem",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "classwork.scoreWeightItem"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "clickable mx-6 mt-2"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.truncateTitle(
                                                                                classwork.text
                                                                              )
                                                                            ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              style: {
                                                                                "font-size":
                                                                                  _vm.categoryFontSize
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.truncateTitle(
                                                                                    classwork.group
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                classwork.source === "gc"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.truncateTitle(
                                                            classwork.text
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.truncateTitle(
                                                            classwork.text
                                                          )
                                                        ) + " "
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          style: {
                                                            "font-size":
                                                              _vm.categoryFontSize
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.truncateTitle(
                                                                classwork.group
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ])
                                              ]
                                            ),
                                            _vm.showStandards
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-nowrap",
                                                    staticStyle: {
                                                      "margin-bottom": "-6px"
                                                    }
                                                  },
                                                  _vm._l(
                                                    classwork.standards,
                                                    function(standard) {
                                                      return _c(
                                                        "div",
                                                        { key: standard.dbId },
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "header-cell rotate clickable",
                                                                                attrs: {
                                                                                  role:
                                                                                    "button"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.editClasswork(
                                                                                      classwork.type,
                                                                                      classwork,
                                                                                      classwork.source
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              "div",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-6 mt-2"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.truncateTitle(
                                                                                          standard.id
                                                                                        )
                                                                                      ) +
                                                                                        " "
                                                                                    ),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        style: {
                                                                                          "font-size":
                                                                                            _vm.categoryFontSize
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "standardLabel"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.truncateTitle(
                                                                      standard.id
                                                                    )
                                                                  ) + " "
                                                                ),
                                                                _c("br"),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    style: {
                                                                      "font-size":
                                                                        _vm.categoryFontSize
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "standardLabel"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  ],
                                  2
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "d-flex flex-nowrap",
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _vm.fullYearMode
                                      ? _c(
                                          "div",
                                          { staticClass: "d-flex flex-nowrap" },
                                          [
                                            _c("div", {
                                              staticClass: "periodcell"
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._l(_vm.periodHeaders, function(header) {
                                      return _c(
                                        "div",
                                        {
                                          key: header.id,
                                          staticClass: "d-flex flex-nowrap"
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "periodcell"
                                          })
                                        ]
                                      )
                                    }),
                                    _vm._l(
                                      _vm.filteredClassworkHeaders,
                                      function(classwork) {
                                        return _c(
                                          "span",
                                          {
                                            key: classwork.id,
                                            staticClass: "d-flex flex-nowrap"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                class: {
                                                  cell: true,
                                                  "missing-date":
                                                    classwork.dueDate == ""
                                                },
                                                staticStyle: {
                                                  "text-align": "center"
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatDateYearless(
                                                        classwork.dueDate
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm.showStandards
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-nowrap"
                                                  },
                                                  _vm._l(
                                                    classwork.standards,
                                                    function(standard) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: standard.dbId,
                                                          class: {
                                                            cell: true,
                                                            "missing-date":
                                                              classwork.dueDate ==
                                                              ""
                                                          },
                                                          staticStyle: {
                                                            "text-align":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatDateYearless(
                                                                  classwork.dueDate
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "d-flex flex-nowrap",
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _vm.fullYearMode
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "evenrow d-flex flex-nowrap"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "periodcell" },
                                              [
                                                _vm.overallHeader.average !=
                                                "NaN"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-nowwrap"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              width: "90px"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ml-3"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .overallHeader
                                                                      .average
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getLetterGrade(
                                                                _vm
                                                                  .overallHeader
                                                                  .average
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._l(_vm.periodHeaders, function(header) {
                                      return _c(
                                        "div",
                                        {
                                          key: header.id,
                                          staticClass:
                                            "evenrow d-flex flex-nowrap"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "periodcell" },
                                            [
                                              header.average != "NaN"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-nowrap"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            width: "90px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "ml-3"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  header.average
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getLetterGrade(
                                                              header.average
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._l(
                                      _vm.filteredClassworkHeaders,
                                      function(classwork) {
                                        return _c(
                                          "span",
                                          {
                                            key: classwork.id,
                                            staticClass:
                                              "evenrow d-flex flex-nowrap"
                                          },
                                          [
                                            _c("div", { staticClass: "cell" }, [
                                              classwork.average != "NaN"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          classwork.average
                                                        ) +
                                                          " / " +
                                                          _vm._s(
                                                            classwork.totalPoints
                                                          )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]),
                                            _vm.showStandards
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-nowrap"
                                                  },
                                                  _vm._l(
                                                    classwork.standards,
                                                    function(standard) {
                                                      return _c("div", {
                                                        key: standard.dbId,
                                                        staticClass: "cell"
                                                      })
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                ref: "scrollGrades",
                                staticClass: "pb-1",
                                staticStyle: {
                                  overflow: "auto",
                                  "max-width": "calc(100vw - 415px)",
                                  "max-height": "calc(100vh - 413px)"
                                },
                                on: {
                                  scroll: function($event) {
                                    _vm.scrollSync(1)
                                    _vm.scrollSyncHorizontal(1)
                                  }
                                }
                              },
                              _vm._l(_vm.sortedStudentData, function(
                                rowData,
                                index
                              ) {
                                return _c(
                                  "v-row",
                                  {
                                    key: rowData.studentId,
                                    staticClass: "d-flex flex-nowrap",
                                    class: { evenrow: index % 2 == 1 },
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-nowrap" },
                                      [
                                        _vm.fullYearMode
                                          ? _c(
                                              "div",
                                              { staticClass: "periodcell" },
                                              [
                                                _c("v-text-field", {
                                                  ref:
                                                    "tabIDXr" + index + "c0s0",
                                                  refInFor: true,
                                                  class: _vm.getStatusClass(
                                                    rowData.percentageStatus
                                                  ),
                                                  staticStyle: {
                                                    width: "90px",
                                                    display: "inline-block"
                                                  },
                                                  attrs: {
                                                    dense: "",
                                                    flat: "",
                                                    solo: "",
                                                    "single-line": "",
                                                    "hide-details": "",
                                                    "append-icon": _vm.getAppendIcon(
                                                      rowData.percentageStatus
                                                    ),
                                                    "aria-label": _vm.$t(
                                                      "overallGradeFor",
                                                      {
                                                        student:
                                                          rowData.studentName
                                                      }
                                                    )
                                                  },
                                                  on: {
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.callTabComplex(
                                                        $event,
                                                        index,
                                                        0,
                                                        0
                                                      )
                                                    },
                                                    keydown: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "tab",
                                                          9,
                                                          $event.key,
                                                          "Tab"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.preventDefault()
                                                      return _vm.callTabComplex(
                                                        $event,
                                                        index,
                                                        0,
                                                        0
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.overridePercentageWrapper(
                                                        rowData
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: rowData.percentage,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        rowData,
                                                        "percentage",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rowData.percentage"
                                                  }
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(rowData.letterGrade)
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-nowrap" },
                                      _vm._l(_vm.periodHeaders, function(
                                        header,
                                        periodIndex
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: header.id,
                                            staticClass: "periodcell"
                                          },
                                          [
                                            _c("v-text-field", {
                                              ref:
                                                "tabIDXr" +
                                                index +
                                                "c" +
                                                (_vm.fullYearMode
                                                  ? 1 + periodIndex
                                                  : periodIndex) +
                                                "s0",
                                              refInFor: true,
                                              class: _vm.getStatusClass(
                                                rowData.periods[header.id]
                                                  .percentageStatus
                                              ),
                                              staticStyle: {
                                                width: "90px",
                                                display: "inline-block"
                                              },
                                              attrs: {
                                                dense: "",
                                                flat: "",
                                                solo: "",
                                                "single-line": "",
                                                "hide-details": "",
                                                "append-icon": _vm.getAppendIcon(
                                                  rowData.periods[header.id]
                                                    .percentageStatus
                                                ),
                                                "aria-label": _vm.$t(
                                                  "periodGradeFor",
                                                  {
                                                    period: header.text,
                                                    student: rowData.studentName
                                                  }
                                                )
                                              },
                                              on: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  _vm.callTabComplex(
                                                    $event,
                                                    index,
                                                    _vm.fullYearMode
                                                      ? 1 + periodIndex
                                                      : periodIndex,
                                                    0
                                                  )
                                                },
                                                keydown: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "tab",
                                                      9,
                                                      $event.key,
                                                      "Tab"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  $event.preventDefault()
                                                  _vm.callTabComplex(
                                                    $event,
                                                    index,
                                                    _vm.fullYearMode
                                                      ? 1 + periodIndex
                                                      : periodIndex,
                                                    0
                                                  )
                                                },
                                                blur: function($event) {
                                                  return _vm.overridePeriodWrapper(
                                                    rowData,
                                                    rowData.periods[
                                                      header.gradePeriodId
                                                    ],
                                                    header.gradePeriodId
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  rowData.periods[header.id]
                                                    .percentage,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    rowData.periods[header.id],
                                                    "percentage",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "rowData.periods[header.id].percentage"
                                              }
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  rowData.periods[header.id]
                                                    .letterGrade
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                    _vm._l(
                                      _vm.filteredClassworkHeaders,
                                      function(classwork, classworkIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: classwork.id,
                                            staticClass: "d-flex flex-nowrap"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "cell",
                                                staticStyle: {
                                                  "text-align": "right"
                                                }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  ref:
                                                    "tabIDXr" +
                                                    index +
                                                    "c" +
                                                    (_vm.fullYearMode
                                                      ? 1 +
                                                        _vm.periodHeaders
                                                          .length +
                                                        classworkIndex
                                                      : _vm.periodHeaders
                                                          .length +
                                                        classworkIndex) +
                                                    "s0",
                                                  refInFor: true,
                                                  staticClass:
                                                    "centerField mr-3",
                                                  class: _vm.getStatusClass(
                                                    rowData[classwork.id].status
                                                  ),
                                                  attrs: {
                                                    dense: "",
                                                    flat: "",
                                                    solo: "",
                                                    "single-line": "",
                                                    "hide-details": "",
                                                    "append-icon": _vm.getAppendIcon(
                                                      rowData[classwork.id]
                                                        .status
                                                    ),
                                                    "aria-label": _vm.$t(
                                                      "classworkScoreFor",
                                                      {
                                                        classwork:
                                                          classwork.text,
                                                        student:
                                                          rowData.studentName
                                                      }
                                                    )
                                                  },
                                                  on: {
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      _vm.callTabComplex(
                                                        $event,
                                                        index,
                                                        _vm.fullYearMode
                                                          ? 1 +
                                                              _vm.periodHeaders
                                                                .length +
                                                              classworkIndex
                                                          : _vm.periodHeaders
                                                              .length +
                                                              classworkIndex,
                                                        0
                                                      )
                                                    },
                                                    keydown: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "tab",
                                                          9,
                                                          $event.key,
                                                          "Tab"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.preventDefault()
                                                      _vm.callTabComplex(
                                                        $event,
                                                        index,
                                                        _vm.fullYearMode
                                                          ? 1 +
                                                              _vm.periodHeaders
                                                                .length +
                                                              classworkIndex
                                                          : _vm.periodHeaders
                                                              .length +
                                                              classworkIndex,
                                                        0
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.saveGrade(
                                                        rowData,
                                                        classwork
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      rowData[classwork.value]
                                                        .score,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        rowData[
                                                          classwork.value
                                                        ],
                                                        "score",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "rowData[classwork.value].score"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _vm.showStandards
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-nowrap"
                                                  },
                                                  _vm._l(
                                                    classwork.standards,
                                                    function(
                                                      standard,
                                                      standardIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: standard.dbId,
                                                          staticClass: "cell",
                                                          class: {
                                                            evenrow:
                                                              index % 2 == 1
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            ref:
                                                              "tabIDXr" +
                                                              index +
                                                              "c" +
                                                              (_vm.fullYearMode
                                                                ? 1 +
                                                                  _vm
                                                                    .periodHeaders
                                                                    .length +
                                                                  classworkIndex
                                                                : _vm
                                                                    .periodHeaders
                                                                    .length +
                                                                  classworkIndex) +
                                                              "s" +
                                                              (standardIndex +
                                                                1),
                                                            refInFor: true,
                                                            class: _vm.getStatusClass(
                                                              rowData[
                                                                classwork.id
                                                              ].standards[
                                                                standard.dbId
                                                              ].status
                                                            ),
                                                            attrs: {
                                                              dense: "",
                                                              flat: "",
                                                              solo: "",
                                                              "single-line": "",
                                                              "hide-details":
                                                                "",
                                                              "append-icon": _vm.getAppendIcon(
                                                                rowData[
                                                                  classwork.id
                                                                ].standards[
                                                                  standard.dbId
                                                                ].status
                                                              ),
                                                              "aria-label": _vm.$t(
                                                                "classworkScoreFor",
                                                                {
                                                                  classwork:
                                                                    standard.id,
                                                                  student:
                                                                    rowData.studentName
                                                                }
                                                              )
                                                            },
                                                            on: {
                                                              keyup: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                _vm.callTabComplex(
                                                                  $event,
                                                                  index,
                                                                  _vm.fullYearMode
                                                                    ? 1 +
                                                                        _vm
                                                                          .periodHeaders
                                                                          .length +
                                                                        classworkIndex
                                                                    : _vm
                                                                        .periodHeaders
                                                                        .length +
                                                                        classworkIndex,
                                                                  standardIndex +
                                                                    1
                                                                )
                                                              },
                                                              keydown: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "tab",
                                                                    9,
                                                                    $event.key,
                                                                    "Tab"
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                $event.preventDefault()
                                                                _vm.callTabComplex(
                                                                  $event,
                                                                  index,
                                                                  _vm.fullYearMode
                                                                    ? 1 +
                                                                        _vm
                                                                          .periodHeaders
                                                                          .length +
                                                                        classworkIndex
                                                                    : _vm
                                                                        .periodHeaders
                                                                        .length +
                                                                        classworkIndex,
                                                                  standardIndex +
                                                                    1
                                                                )
                                                              },
                                                              blur: function(
                                                                $event
                                                              ) {
                                                                return _vm.saveStandardWrapper(
                                                                  rowData[
                                                                    classwork.id
                                                                  ].standards[
                                                                    standard
                                                                      .dbId
                                                                  ],
                                                                  rowData,
                                                                  classwork.type
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                rowData[
                                                                  classwork.id
                                                                ].standards[
                                                                  standard.dbId
                                                                ].code,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  rowData[
                                                                    classwork.id
                                                                  ].standards[
                                                                    standard
                                                                      .dbId
                                                                  ],
                                                                  "code",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "rowData[classwork.id].standards[standard.dbId].code"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              }),
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            : _c(
                "v-card",
                { staticClass: "mt-4 mb-4 ml-4 mr-2", attrs: { flat: "" } },
                [
                  _vm.hasGoogleGrades
                    ? _c(
                        "v-row",
                        { staticClass: "mt-2 mb-4 ml-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", dense: "" },
                              on: { click: _vm.refreshTable }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("refreshGoogle")))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticClass: "mt-4 ml-0" },
                    [
                      _vm.currentStudentData.source == "gc"
                        ? _c("v-icon", {
                            staticClass: "fa-brands fa-google mt-1 mr-2",
                            attrs: { text: "" }
                          })
                        : _c(
                            "v-avatar",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "primary", size: "2.5rem" }
                            },
                            [
                              _vm.currentStudentData.photoUrl &&
                              _vm.currentStudentData.photoUrl !== ""
                                ? _c("v-img", {
                                    attrs: {
                                      src: _vm.currentStudentData.photoUrl
                                    }
                                  })
                                : _c("span", { staticClass: "white--text" }, [
                                    _vm._v(
                                      _vm._s(_vm.currentStudentData.initials)
                                    )
                                  ])
                            ],
                            1
                          ),
                      _c("span", { staticClass: "mt-2" }, [
                        _vm._v(_vm._s(_vm.currentStudentData.name))
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          attrs: { color: "primary", text: "", outlined: "" },
                          on: { click: _vm.exitSingleStudent }
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("allLabel")))])]
                      ),
                      _vm.currentStudentData.previousStudentId
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                color: "primary",
                                text: "",
                                outlined: ""
                              },
                              on: { click: _vm.priorStudent }
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("priorLabel")))])]
                          )
                        : _vm._e(),
                      _vm.currentStudentData.nextStudentId
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                color: "primary",
                                text: "",
                                outlined: ""
                              },
                              on: { click: _vm.nextStudent }
                            },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("nextLabel")))])]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-row", { staticClass: "mt-10 mb-2 ml-0" }, [
                    _c("span", { staticClass: "mt-2" }, [
                      _vm._v(_vm._s(_vm.$t("percentageLabel")))
                    ]),
                    _c(
                      "span",
                      { staticClass: "ml-4", staticStyle: { width: "70px" } },
                      [
                        _c("v-text-field", {
                          ref: "percentageField",
                          class: _vm.getStatusClass(
                            _vm.currentStudentData.percentageStatus
                          ),
                          attrs: {
                            "append-icon": _vm.getAppendIcon(
                              _vm.currentStudentData.percentageStatus
                            ),
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "aria-label": _vm.$t("overallLabel")
                          },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return $event.target.blur()
                            },
                            blur: function($event) {
                              return _vm.saveOverridePercentageSM()
                            }
                          },
                          model: {
                            value: _vm.currentStudentData.percentage,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.currentStudentData,
                                "percentage",
                                $$v
                              )
                            },
                            expression: "currentStudentData.percentage"
                          }
                        })
                      ],
                      1
                    ),
                    _c("span", { staticClass: "mt-2 ml-4" }, [
                      _vm._v(
                        _vm._s(_vm.$t("letterGradeLabel")) +
                          ": " +
                          _vm._s(_vm.currentStudentData.letterGrade)
                      )
                    ])
                  ]),
                  _c(
                    "perfect-scrollbar",
                    { style: { height: _vm.singleStudentScrollheight } },
                    [
                      _c(
                        "div",
                        { staticClass: "mr-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "px-4 blue-background",
                              attrs: { fluid: "" }
                            },
                            [_vm._v(_vm._s(_vm.$t("assignmentsLabel")))]
                          ),
                          _c("v-data-table", {
                            staticStyle: { border: "1px solid #dddddd" },
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.filteredStudentAssignmentsTable,
                              "hide-default-footer": "",
                              "items-per-page": -1,
                              "fixed-header": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.title",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "clickable",
                                          staticStyle: {
                                            "border-right": "1px solid #dddddd"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.editClasswork(
                                                "g",
                                                item,
                                                item.source
                                              )
                                            }
                                          }
                                        },
                                        [
                                          item.source == "gc"
                                            ? _c("v-icon", {
                                                staticClass:
                                                  "fa-brands fa-google mr-2",
                                                staticStyle: {
                                                  display: "inline-block"
                                                },
                                                attrs: { text: "", small: "" }
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clickable",
                                              staticStyle: {
                                                display: "inline-block"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.title))]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.scoreWeightName",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "pl-0 pr-0",
                                          class: {
                                            clickable: item.source == "gc"
                                          },
                                          staticStyle: {
                                            "border-right": "1px solid #dddddd"
                                          }
                                        },
                                        [
                                          item.source != "gc"
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.scoreWeightName)
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    right: "",
                                                    "close-on-click": "",
                                                    absolute: "",
                                                    "close-on-content-click": false
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-container",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "clickable",
                                                                    class: _vm
                                                                      .$vuetify
                                                                      .breakpoint
                                                                      .smAndDown
                                                                      ? "outlined"
                                                                      : "",
                                                                    staticStyle: {
                                                                      "justify-content":
                                                                        "center"
                                                                    },
                                                                    attrs: {
                                                                      "fill-height":
                                                                        "",
                                                                      "fill-width":
                                                                        ""
                                                                    }
                                                                  },
                                                                  "v-container",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.scoreWeightName
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "white"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mx-4 mb-4"
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "selectScoreWeight"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c("v-select", {
                                                            staticClass: "mt-1",
                                                            attrs: {
                                                              dense: "",
                                                              "display:inline-flex":
                                                                "",
                                                              "return-object":
                                                                "",
                                                              "hide-details":
                                                                "auto",
                                                              items:
                                                                _vm.assignmentCategories,
                                                              "item-text":
                                                                "name",
                                                              outlined: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.changeCategoryGoogle(
                                                                  item,
                                                                  item.scoreWeightItem
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item.scoreWeightItem,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "scoreWeightItem",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.scoreWeightItem"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.points",
                                  fn: function(ref) {
                                    var item = ref.item
                                    var index = ref.index
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "border-right": "1px solid #dddddd",
                                            "white-space": "nowrap"
                                          }
                                        },
                                        [
                                          item.scoreWeightName != "Standard"
                                            ? _c(
                                                "div",
                                                [
                                                  _c("v-text-field", {
                                                    ref: "tabIDX" + (1 + index),
                                                    class: _vm.getStatusClass(
                                                      item.score.status
                                                    ),
                                                    staticStyle: {
                                                      width: "50px",
                                                      display: "inline-block"
                                                    },
                                                    attrs: {
                                                      "append-icon": _vm.getAppendIcon(
                                                        item.score.status
                                                      ),
                                                      dense: "",
                                                      flat: "",
                                                      solo: "",
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      outlined:
                                                        _vm.$vuetify.breakpoint
                                                          .smAndDown,
                                                      "aria-label": _vm.$t(
                                                        "itemScoreLabel",
                                                        { item: item.title }
                                                      )
                                                    },
                                                    on: {
                                                      keyup: function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.callTab(
                                                          $event,
                                                          1 + index
                                                        )
                                                      },
                                                      keydown: function(
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "tab",
                                                            9,
                                                            $event.key,
                                                            "Tab"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        return _vm.callTab(
                                                          $event,
                                                          1 + index
                                                        )
                                                      },
                                                      blur: function($event) {
                                                        return _vm.saveGradeSingleStudent(
                                                          item,
                                                          "g"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: item.score.points,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item.score,
                                                          "points",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.score.points"
                                                    }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "ml-2",
                                                      staticStyle: {
                                                        display: "inline-block"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " / " +
                                                          _vm._s(item.possible)
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c("v-text-field", {
                                                    ref: "tabIDX" + (1 + index),
                                                    class: _vm.getStatusClass(
                                                      item.status
                                                    ),
                                                    attrs: {
                                                      "append-icon": _vm.getAppendIcon(
                                                        item.status
                                                      ),
                                                      outlined:
                                                        _vm.$vuetify.breakpoint
                                                          .smAndDown,
                                                      dense: "",
                                                      flat: "",
                                                      solo: "",
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      "aria-label": _vm.$t(
                                                        "itemScoreLabel",
                                                        { item: item.title }
                                                      )
                                                    },
                                                    on: {
                                                      keyup: function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.callTab(
                                                          $event,
                                                          1 + index
                                                        )
                                                      },
                                                      keydown: function(
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "tab",
                                                            9,
                                                            $event.key,
                                                            "Tab"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        return _vm.callTab(
                                                          $event,
                                                          1 + index
                                                        )
                                                      },
                                                      blur: function($event) {
                                                        return _vm.saveStandardWrapper(
                                                          item,
                                                          _vm.currentStudentData,
                                                          "g"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: item.code,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "code",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.code"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.end",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "border-right": "1px solid #dddddd",
                                            "white-space": "nowrap"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.formatDate(item.end))
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.noteText",
                                  fn: function(ref) {
                                    var item = ref.item
                                    var index = ref.index
                                    return [
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            ref:
                                              "tabIDX" +
                                              (1 +
                                                index +
                                                _vm
                                                  .filteredStudentAssignmentsTable
                                                  .length +
                                                _vm
                                                  .filteredStudentAssessmentsTable
                                                  .length),
                                            class: _vm.getStatusClass(
                                              item.noteStatus
                                            ),
                                            attrs: {
                                              "append-icon": _vm.getAppendIcon(
                                                item.noteStatus
                                              ),
                                              outlined:
                                                _vm.$vuetify.breakpoint
                                                  .smAndDown,
                                              dense: "",
                                              flat: "",
                                              solo: "",
                                              "single-line": "",
                                              "hide-details": "",
                                              "aria-label": _vm.$t(
                                                "noteForLabel",
                                                { item: item.title }
                                              )
                                            },
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.callTab(
                                                  $event,
                                                  1 +
                                                    index +
                                                    _vm
                                                      .filteredStudentAssignmentsTable
                                                      .length +
                                                    _vm
                                                      .filteredStudentAssessmentsTable
                                                      .length
                                                )
                                              },
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "tab",
                                                    9,
                                                    $event.key,
                                                    "Tab"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.callTab(
                                                  $event,
                                                  1 +
                                                    index +
                                                    _vm
                                                      .filteredStudentAssignmentsTable
                                                      .length +
                                                    _vm
                                                      .filteredStudentAssessmentsTable
                                                      .length
                                                )
                                              },
                                              blur: function($event) {
                                                return _vm.saveNote(item, true)
                                              }
                                            },
                                            model: {
                                              value: item.noteText,
                                              callback: function($$v) {
                                                _vm.$set(item, "noteText", $$v)
                                              },
                                              expression: "item.noteText"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "mt-10 px-4 blue-background",
                              attrs: { fluid: "" }
                            },
                            [_vm._v(_vm._s(_vm.$t("assessmentsLabel")))]
                          ),
                          _c("v-data-table", {
                            staticStyle: { border: "1px solid #dddddd" },
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.filteredStudentAssessmentsTable,
                              "hide-default-footer": "",
                              "items-per-page": -1,
                              "fixed-header": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.title",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "clickable",
                                          staticStyle: {
                                            "border-right": "1px solid #dddddd"
                                          },
                                          on: {
                                            click: function($event) {
                                              item.scoreWeightName != "Standard"
                                                ? _vm.editClasswork(
                                                    "m",
                                                    item,
                                                    item.source
                                                  )
                                                : _vm.editClasswork(
                                                    "m",
                                                    item,
                                                    item.source
                                                  )
                                            }
                                          }
                                        },
                                        [
                                          item.source == "gc"
                                            ? _c("v-icon", {
                                                staticClass:
                                                  "fa-brands fa-google mr-2",
                                                staticStyle: {
                                                  display: "inline-block"
                                                },
                                                attrs: { text: "", small: "" }
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clickable",
                                              staticStyle: {
                                                display: "inline-block"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.title))]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.scoreWeightName",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "pl-0 pr-0",
                                          class: {
                                            clickable: item.source == "gc"
                                          },
                                          staticStyle: {
                                            "border-right": "1px solid #dddddd"
                                          }
                                        },
                                        [
                                          item.source != "gc"
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.scoreWeightName)
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    right: "",
                                                    "close-on-click": "",
                                                    absolute: "",
                                                    "close-on-content-click": false
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-container",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "clickable",
                                                                    staticStyle: {
                                                                      "justify-content":
                                                                        "center"
                                                                    },
                                                                    attrs: {
                                                                      "fill-height":
                                                                        "",
                                                                      "fill-width":
                                                                        ""
                                                                    }
                                                                  },
                                                                  "v-container",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.scoreWeightName
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "white"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mx-4 mb-4"
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "selectScoreWeight"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c("v-select", {
                                                            staticClass: "mt-1",
                                                            attrs: {
                                                              dense: "",
                                                              "display:inline-flex":
                                                                "",
                                                              "return-object":
                                                                "",
                                                              "hide-details":
                                                                "auto",
                                                              items:
                                                                _vm.assignmentCategories,
                                                              "item-text":
                                                                "name",
                                                              outlined: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.changeCategoryGoogle(
                                                                  item,
                                                                  item.scoreWeightItem
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item.scoreWeightItem,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "scoreWeightItem",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.scoreWeightItem"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.end",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "border-right": "1px solid #dddddd",
                                            "white-space": "nowrap"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.formatDate(item.end))
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.points",
                                  fn: function(ref) {
                                    var item = ref.item
                                    var index = ref.index
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "border-right": "1px solid #dddddd",
                                            "white-space": "nowrap"
                                          }
                                        },
                                        [
                                          item.scoreWeightName != "Standard"
                                            ? _c(
                                                "div",
                                                [
                                                  _c("v-text-field", {
                                                    ref:
                                                      "tabIDX" +
                                                      (1 +
                                                        index +
                                                        _vm
                                                          .filteredStudentAssignmentsTable
                                                          .length),
                                                    class: _vm.getStatusClass(
                                                      item.score.status
                                                    ),
                                                    staticStyle: {
                                                      width: "50px",
                                                      display: "inline-block"
                                                    },
                                                    attrs: {
                                                      "append-icon": _vm.getAppendIcon(
                                                        item.score.status
                                                      ),
                                                      dense: "",
                                                      flat: "",
                                                      solo: "",
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      outlined:
                                                        _vm.$vuetify.breakpoint
                                                          .smAndDown,
                                                      "aria-label": _vm.$t(
                                                        "itemScoreLabel",
                                                        { item: item.title }
                                                      )
                                                    },
                                                    on: {
                                                      keyup: function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.callTab(
                                                          $event,
                                                          1 +
                                                            index +
                                                            _vm
                                                              .filteredStudentAssignmentsTable
                                                              .length
                                                        )
                                                      },
                                                      keydown: function(
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "tab",
                                                            9,
                                                            $event.key,
                                                            "Tab"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        return _vm.callTab(
                                                          $event,
                                                          1 +
                                                            index +
                                                            _vm
                                                              .filteredStudentAssignmentsTable
                                                              .length
                                                        )
                                                      },
                                                      blur: function($event) {
                                                        return _vm.saveGradeSingleStudent(
                                                          item,
                                                          "m"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: item.score.points,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item.score,
                                                          "points",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.score.points"
                                                    }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "ml-2",
                                                      staticStyle: {
                                                        display: "inline-block"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " / " +
                                                          _vm._s(item.possible)
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c("v-text-field", {
                                                    ref:
                                                      "tabIDX" +
                                                      (1 +
                                                        index +
                                                        _vm
                                                          .filteredStudentAssignmentsTable
                                                          .length),
                                                    class: _vm.getStatusClass(
                                                      item.status
                                                    ),
                                                    attrs: {
                                                      "append-icon": _vm.getAppendIcon(
                                                        item.status
                                                      ),
                                                      dense: "",
                                                      flat: "",
                                                      solo: "",
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      outlined:
                                                        _vm.$vuetify.breakpoint
                                                          .smAndDown,
                                                      "aria-label": _vm.$t(
                                                        "itemScoreLabel",
                                                        { item: item.title }
                                                      )
                                                    },
                                                    on: {
                                                      keyup: function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.callTab(
                                                          $event,
                                                          1 +
                                                            index +
                                                            _vm
                                                              .filteredStudentAssignmentsTable
                                                              .length
                                                        )
                                                      },
                                                      keydown: function(
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "tab",
                                                            9,
                                                            $event.key,
                                                            "Tab"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        return _vm.callTab(
                                                          $event,
                                                          1 +
                                                            index +
                                                            _vm
                                                              .filteredStudentAssignmentsTable
                                                              .length
                                                        )
                                                      },
                                                      blur: function($event) {
                                                        return _vm.saveStandardWrapper(
                                                          item,
                                                          _vm.currentStudentData,
                                                          "m"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: item.code,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "code",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.code"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.noteText",
                                  fn: function(ref) {
                                    var item = ref.item
                                    var index = ref.index
                                    return [
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            ref:
                                              "tabIDX" +
                                              (1 +
                                                index +
                                                2 *
                                                  _vm
                                                    .filteredStudentAssignmentsTable
                                                    .length +
                                                _vm
                                                  .filteredStudentAssessmentsTable
                                                  .length),
                                            class: _vm.getStatusClass(
                                              item.noteStatus
                                            ),
                                            attrs: {
                                              "append-icon": _vm.getAppendIcon(
                                                item.noteStatus
                                              ),
                                              dense: "",
                                              flat: "",
                                              solo: "",
                                              "single-line": "",
                                              "hide-details": "",
                                              outlined:
                                                _vm.$vuetify.breakpoint
                                                  .smAndDown,
                                              "aria-label": _vm.$t(
                                                "noteForLabel",
                                                { item: item.title }
                                              )
                                            },
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                _vm.callTab(
                                                  $event,
                                                  1 +
                                                    index +
                                                    2 *
                                                      _vm
                                                        .filteredStudentAssignmentsTable
                                                        .length +
                                                    _vm
                                                      .filteredStudentAssessmentsTable
                                                      .length
                                                )
                                              },
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "tab",
                                                    9,
                                                    $event.key,
                                                    "Tab"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                _vm.callTab(
                                                  $event,
                                                  1 +
                                                    index +
                                                    2 *
                                                      _vm
                                                        .filteredStudentAssignmentsTable
                                                        .length +
                                                    _vm
                                                      .filteredStudentAssessmentsTable
                                                      .length
                                                )
                                              },
                                              blur: function($event) {
                                                return _vm.saveNote(item, false)
                                              }
                                            },
                                            model: {
                                              value: item.noteText,
                                              callback: function($$v) {
                                                _vm.$set(item, "noteText", $$v)
                                              },
                                              expression: "item.noteText"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }