
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import StudentUtils from '@/utils/student-utils';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import moment from 'moment';

const students = namespace('students');
const settings = namespace('settings');

@Component
export default class GradesTable extends Vue {
  @Prop({ type: Number })
  studentId!: number;

  @students.Action
  loadScoresForStudent!: (params?: any) => Promise<any>;

  @students.Action
  getSchoolYears!: (params?: any) => Promise<any>;

  @students.Getter
  getAdminSchoolYears!: any;

  @settings.Getter('getSchools')
  schools!: any;

  @settings.Getter('getPrimarySchool')
  primarySchool!: any;

  @settings.Getter('getGlobalLanguage')
  globalLanguage!: any;

  loading = false;
  loadingYears = false;
  currentStudent: any = '';
  associatedTeachers: Array<any> = [];
  selectedTeacher: any = null;
  teacherOptions: Array<any> = [];
  selectedYear: any = { yearId: 0, yearName: this.$t('currentLabel') };
  selectedDate = moment().format('YYYY-MM-DD');
  itemFocused = '';
  showDatePicker = false;

  get haveMultipleSchools() {
    return this.schools.length > 1;
  }

  get schoolConnected() {
    return this.yearOptions.length > 1;
  }

  get yearOptions() {
    return [{ yearId: 0, yearName: this.$t('currentLabel') }].concat(this.getAdminSchoolYears);
  }

  get getGlobalLanguage() {
    return this.globalLanguage || 'en';
  }

  get dateText() {
    return this.formatToShow(this.selectedDate);
  }

  set dateText(val: any) {
    this.selectedDate = DateTimeUtils.formatToSort(val);
  }

  private formatToShow(date: string) {
    return DateTimeUtils.formatToShow(moment(date).format('MM/DD/YYYY'));
  }

  created() {
    this.loadStudent();
    if (this.$currentUser.isStudent) {
      this.loadingYears = true;
      this.getSchoolYears().then(() => {
        this.loadingYears = false;
      })
    }
  }

  @Watch('showDatePicker')
  focusDatePicker() {
    if (this.showDatePicker) {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          try {
            (this.$refs as any).datePicker.$el.querySelector('.v-date-picker-header__value').querySelector('button').focus()
          } catch (e) {
            console.log(this.$t('focusFailed'));
          }
        });
      });
    }
  }

  @Watch('selectedYear')
  @Watch('selectedDate')
  @Watch('studentId')
  loadStudent() {
    if (this.studentId === 0) {
      return;
    }
    this.loading = true;
    CommonUtils.showLoading();
    const payload:any = { studentId: this.studentId };
    if (this.$currentUser.isDistrictAdmin || this.haveMultipleSchools) {
      payload.currentSchoolId = (this.$store.state.teachers.currentSchoolId || this.primarySchool.schoolId);
    }
    if (this.$currentUser.isStudent) {
      if (this.selectedYear.yearId > 0) {
        payload.yearId = this.selectedYear.yearId;
      }
      if (this.selectedDate !== DateTimeUtils.currentDateAddDays(0)) {
        payload.asOf = this.dateText;
      }
    }
    this.loadScoresForStudent(payload).then((d: any) => {
      this.associatedTeachers = d.data.teachers.map((t: any) => {
        t.subjects = {};
        t.subjectsList = [];
        t.expanded = [];
        t.standards = [];
        t.gradePeriods = [];
        return t;
      });
      this.teacherOptions = [{ displayName: this.$t('allTeachersLabel'), teacherId: 0 }].concat(d.data.teachers);
      this.selectedTeacher = this.teacherOptions[0]
      d.data.subjects.map((s: any) => {
        s.standards = [];
        const teacher = this.associatedTeachers.find((t: any) => t.yearId === s.yearId);
        if (s.gradePeriod) {
          if (teacher.subjects[s.subjectId]) {
            teacher.subjects[s.subjectId].periods.push(s);
          } else {
            teacher.subjects[s.subjectId] = { info: s, periods: [s], teacher: teacher };
          }
        } else {
          teacher.subjects[s.subjectId] = { info: s, periods: [], teacher: teacher };
        }
        teacher.subjectsList.push(s);
      })

      d.data.standards.map((s: any) => {
        const teacher = this.associatedTeachers.find((t: any) => t.yearId === s.yearId);
        teacher.standards.push(s);
      })

      d.data.gradePeriods.map((p: any) => {
        const teacher = this.associatedTeachers.find((t: any) => t.yearId === p.yearId);
        teacher.gradePeriods.push(p);
      })
      CommonUtils.hideLoading();
      this.loading = false;
    })
  }

  mobileHeaders = [{ text: '', value: 'class', sortable: false },
    { text: '', value: 'score', sortable: false, width: 10 },
    { text: '', value: 'action', sortable: false, width: 10 }]

  get assignedClasses() {
    const subjects: any = [];
    this.associatedTeachers.filter((t: any) => t.teacherId === this.selectedTeacher.teacherId || this.selectedTeacher.teacherId === 0).map((t: any) => {
      for (const s in t.subjects) {
        subjects.push(t.subjects[s])
      }
    })
    return subjects;
  }

  get getName() {
    return StudentUtils.getStudentLabel;
  }

  // Viewing Pop-up table for Student
  viewingStudent = false;
  viewingSubjects: Array<any> = [];
  viewingStandards: Array<any> = [];
  showAssignments = true;
  showAssessments = true;
  showStandards = true;

  startView() {
    this.viewingStudent = true;
    this.showAssignments = true;
    this.showAssessments = true;
    this.showStandards = true;
  }

  viewTeacher(teacher: any) {
    this.viewingSubjects = teacher.subjectsList
    this.viewingStandards = teacher.standards.filter((s: any) => s.subjectId === 0 && s.gradePeriodId === 0);
    this.startView();
  }

  viewPeriod(teacher: any, period: any) {
    this.viewingSubjects = teacher.subjectsList.filter((s: any) => s.gradePeriod.gradePeriodId === period.gradePeriodId);
    this.viewingStandards = teacher.standards.filter((s: any) => s.subjectId === 0 && s.gradePeriodId === period.gradePeriodId);
    this.startView();
  }

  viewClass(teacher: any, item: any) {
    this.viewingSubjects = teacher.subjectsList.filter((s: any) => s.subjectId === item.info.subjectId);
    this.viewingStandards = teacher.standards.filter((s: any) => s.subjectId === item.info.subjectId && s.gradePeriodId === 0);
    this.startView();
  }

  viewClassPeriod(teacher: any, item: any, period: any) {
    this.viewingSubjects = teacher.subjectsList.filter((s: any) => s.subjectId === item.info.subjectId && s.gradePeriod.gradePeriodId === period.gradePeriod.gradePeriodId);
    this.viewingStandards = teacher.standards.filter((s: any) => s.subjectId === item.info.subjectId && s.gradePeriodId === period.gradePeriod.gradePeriodId);
    this.startView();
  }
}
