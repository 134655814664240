import { render, staticRenderFns } from "./PerformanceReport.vue?vue&type=template&id=6911b121&scoped=true"
import script from "./PerformanceReport.vue?vue&type=script&lang=ts"
export * from "./PerformanceReport.vue?vue&type=script&lang=ts"
import style0 from "./PerformanceReport.vue?vue&type=style&index=0&id=6911b121&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6911b121",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCheckbox,VCol,VContainer,VForm,VIcon,VListItem,VListItemAction,VListItemContent,VListItemTitle,VRow,VSelect,VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6911b121')) {
      api.createRecord('6911b121', component.options)
    } else {
      api.reload('6911b121', component.options)
    }
    module.hot.accept("./PerformanceReport.vue?vue&type=template&id=6911b121&scoped=true", function () {
      api.rerender('6911b121', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/grades/PerformanceReport.vue"
export default component.exports